import { toast } from "react-toastify"
import { promiseState } from "../../helper"
import { magazine } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
  isLoading: false,
  errors: [],
  message: "",
  detail: {},
  data: [],
  index: [],
}

function magazineReducer(state = initialState, action) {
  switch (action.type) {
    case pendingState(magazine.GET_LAST_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(magazine.GET_LAST_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(magazine.GET_LAST_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
        detail: action.payload.data.data,
      })
    }

    case pendingState(magazine.CHANGE_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(magazine.CHANGE_MAGAZINE): {
      toast.error(
        action?.payload?.response
          ? action?.payload?.response.data.message
          : action?.payload?.message
      )
      return Object.assign({}, state, {
        isLoading: false,
        message: action?.payload?.response
          ? action?.payload?.response.data.message
          : action?.payload?.message,
      })
    }

    case fulfilledState(magazine.CHANGE_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
        detail: action.payload.data.data,
      })
    }

    case pendingState(magazine.GET_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(magazine.GET_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(magazine.GET_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
        data: action.payload.data.data,
      })
    }

    case pendingState(magazine.DETAIL_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(magazine.DETAIL_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(magazine.DETAIL_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
        detail: action.payload.data.data,
      })
    }

    case pendingState(magazine.TOTAL_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(magazine.TOTAL_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(magazine.TOTAL_MAGAZINE): {
      return Object.assign({}, state, {
        isLoading: false,
        index: Array.from(
          {
            length:
              action.payload.data.data === 10
                ? 10
                : action.payload.data.data + 1,
          },
          (_, i) => i + 1
        ),
      })
    }

    default:
      return state
  }
}

export default magazineReducer
