import { banner } from "../types"
import { axiosInstance } from "../../../helper"

export function updateEventBanner(id, body, config) {
  return {
    type: banner.UPDATE_EVENT_BANNER,
    payload: new axiosInstance.put(`/event-banner/${id}`, body, config),
  }
}

export default updateEventBanner
