import { Component } from "react"
import {
  Container,
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Button,
  Row,
  Col,
  CardHeader,
} from "reactstrap"

import { imageSystem } from "../../redux/actions"

import { connect } from "react-redux"
import { File } from "../Form"

class SplashScreen extends Component {
  constructor() {
    super()
    this.state = {
      isModalShow: false,
      image: null,
      splashIsOpenCollapse: false,
    }
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  editImage(e) {
    e.preventDefault()
    const formData = new FormData()

    formData.append("image", this.state.image)

    return this.props.editImage("splash", formData).then(() => {
      this.setState(
        {
          image: null,
          isModalShow: false,
        },
        () => {
          this.getSplashScreen()
        }
      )
    })
  }

  getSplashScreen() {
    this.props.getSplashScreen()
  }

  componentDidMount() {
    this.getSplashScreen()
  }

  render() {
    console.log(this.props.imageSystem)
    return (
      <Col xl={12} className="mb-3">
        <Card>
          <CardHeader
            onClick={() => {
              this.setState({
                splashIsOpenCollapse: !this.state.splashIsOpenCollapse,
              })
            }}
            className={`border-0 toggler-collapse ${
              this.state.splashIsOpenCollapse ? "glow-collapse-active" : ""
            }`}
          >
            Splash Screen
          </CardHeader>

          <Collapse isOpen={this.state.splashIsOpenCollapse}>
            <CardBody>
              <Row className="d-flex justify-content-center">
                <div className="d-flex justify-content-center col-12 pb-3">
                  <img
                    src={this.props.imageSystem.image}
                    alt="splash screen"
                    style={{
                      maxWidth: "300px",
                    }}
                  />
                </div>

                <Button color="primary" onClick={this.modalToggle.bind(this)}>
                  Ganti Photo
                </Button>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
        <Modal
          toggle={this.modalToggle.bind(this)}
          isOpen={this.state.isModalShow}
        >
          <ModalHeader
            toggle={this.modalToggle.bind(this)}
            cssModule={{
              "modal-title": "h3",
            }}
          >
            Change Splash Screen
          </ModalHeader>
          <ModalBody>
            <Container>
              <Form onSubmit={this.editImage.bind(this)} id="change-ig-profile">
                <FormGroup>
                  <Label htmlFor="image">Image File </Label>

                  <File
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      this.setState({ image: e.target.files[0] })
                    }}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    outline
                    onClick={this.modalToggle.bind(this)}
                    type="button"
                  >
                    Batal
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    form="change-ig-profile"
                  >
                    Simpan
                  </Button>
                </div>
              </Form>
            </Container>
          </ModalBody>
        </Modal>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    imageSystem: state.imageSystem.splash,
  }
}

const mapDispatchToProps = {
  getSplashScreen: imageSystem.getSplashScreen,
}

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen)
