import { Component } from "react"
import { connect } from "react-redux"
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Nav,
  NavLink,
  NavItem,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import { Link, NavLink as NavLinkRRD } from "react-router-dom"
import { banner } from "../../../redux/actions"
import Swal from "sweetalert2"
import { Icon } from "@iconify/react"
import { toast } from "react-toastify"

class InformationBanner extends Component {
  getInfoBanner() {
    this.props.getInfoBanner()
  }

  deleteInfoBanner(id) {
    this.props.deleteInfoBanner(id).then(() => {
      toast.success("Berhasil Hapus Banner")
      this.getInfoBanner()
    })
  }

  updateInfo(id, data) {
    this.props.updateInfoBanner(id, data).then(() => {
      toast.success("Update Banner Berhasil")
      this.getInfoBanner()

      this.forceUpdate()
    })
  }

  handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        return this.deleteInfoBanner(id)
      }
    })
  }

  componentDidMount() {
    this.getInfoBanner()
  }

  render() {
    return (
      <Container fluid className="mb-5">
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Banner</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="schedule-nav mb-3">
          <Nav>
            <NavItem>
              <NavLink tag={NavLinkRRD} to="/admin/new-banner/information">
                Banner Informasi
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={NavLinkRRD} to="/admin/new-banner/event">
                Banner Event
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <p>*Maksimal upload 10 Banner</p>

        <div className="d-flex justify-content-end">
          {/* Page content */}
          <Link
            to="/admin/new-banner/information/create"
            className="btn btn-primary"
          >
            Tambah Banner
          </Link>
        </div>

        <Row className="mb-4 mt-3">
          {this.props.banner.infoData.map((info) => (
            <Col xl={4}>
              <Card className="mb-3 banner">
                <div className="card-img-top">
                  <img src={info.image} alt={info.image} width="100%" />
                  <div
                    className="del-card"
                    onClick={() => {
                      this.handleDelete(info.id)
                    }}
                  >
                    <Icon icon="akar-icons:circle-x-fill" fontSize={"35px"} />
                  </div>
                </div>
                <CardBody className="d-flex justify-content-between">
                  <h5>{info.title}</h5>
                  <div className="d-flex">
                    <select
                      className="form-control form-control-sm ml-auto"
                      defaultValue={info.priority}
                      value={info.priority}
                      onChange={(e) => {
                        const formData = new FormData()
                        formData.append("title", info.title)
                        formData.append("index", e.target.value)
                        formData.append("description", info.description)

                        this.updateInfo(info.id, formData)
                      }}
                    >
                      {Array(this.props.banner.infoData.length)
                        .fill(1)
                        .map((el, i) => (
                          <option value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    banner: state.banner,
  }
}

const mapDispatchToProps = {
  getInfoBanner: banner.getInfoBanner,
  deleteInfoBanner: banner.deleteInfoBanner,
  updateInfoBanner: banner.updateInfoBanner,
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationBanner)
