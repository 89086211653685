import { axiosInstance } from "../../../helper"
import { banner } from "../types"

export function getEventBanner(params, config) {
  return {
    type: banner.TOTAL_EVENT_BANNER,
    payload: new axiosInstance.get("/event-banner/indexTotal", {
      ...config,
      params,
    }),
  }
}

export default getEventBanner
