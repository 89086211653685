import { promiseState } from "../../helper"
import { leader } from "../../redux/actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
  isLoading: false,
  errors: [],
  message: "",
  data: [],
  detail: {},
}

function leaderReducer(state = initialState, action) {
  switch (action.type) {
    case pendingState(leader.GET_LEADER): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(leader.GET_LEADER): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(leader.GET_LEADER): {
      return Object.assign({}, state, {
        isLoading: false,
        data: action.payload.data.data,
      })
    }

    case pendingState(leader.GET_DETAIL): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(leader.GET_DETAIL): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(leader.GET_DETAIL): {
      return Object.assign({}, state, {
        isLoading: false,
        detail: action.payload.data.data,
      })
    }

    default:
      return state
  }
}

export default leaderReducer
