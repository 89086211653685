import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"
import CreatableSelect from "react-select/creatable"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { connect } from "react-redux"
import { church, event } from "../../../redux/actions"
import Swal from "sweetalert2"
import { File } from "../../../components/Form"
import moment from "moment"

class CreateEventSchedule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      date: null,
      dateDisabled: false,
      time: null,
      schedules: [],

      name: "",
      churchId: null,
      placeValue: null,
      address: "",
      description: "",
      capacity: "",
      image: null,
      file_name: null,
      place_name: null,
      isModalShow: false,
      showImage: null,
      is_bookable: true,

      readOnly: this.props.match.params.eventId !== "create",

      editMode: false,
    }
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  goBack() {
    if (this.props.match.params.eventId === "create") {
      this.props.history.push("/admin/schedule/event")
    }

    return this.setState({
      readOnly: !this.state.readOnly,
    })
  }

  addSchedules() {
    if (!this.state.date) {
      return toast.error("Mohon isi Tanggal")
    }

    if (!this.state.time) {
      return toast.error("Mohon isi Waktu")
    }

    const oldSchedule = this.state.schedules
    const index = oldSchedule.findIndex((v) => {
      return v.date === this.state.date
    })

    if (index === -1) {
      oldSchedule.push({
        date: this.state.date,
        times: [this.state.time],
      })
    } else {
      oldSchedule[index].times.push(this.state.time)
    }

    return this.setState({
      schedules: oldSchedule,
      time: "",
      dateDisabled: true,
    })
  }

  deleteSchedule(dateKey, timeKey) {
    const oldSchedule = this.state.schedules

    const finder = oldSchedule[dateKey].times.filter(
      (value, index) => index !== timeKey
    )

    if (finder.length === 0) {
      delete oldSchedule[dateKey]
    } else {
      oldSchedule[dateKey].times = finder
    }

    this.setState({
      schedules: oldSchedule,
      dateDisabled: finder.length !== 0,
    })
  }

  addEvent() {
    this.props
      .addEvent({
        name: this.state.name,
        place_name: this.state.place_name,
        capacity: this.state.capacity,
        description: this.state.description,
        image: this.state.image,
        church_id: this.state.churchId,
        address: this.state.address,
        is_bookable: this.state.is_bookable ? "1" : "0",
        type: "2",
        schedules: this.state.schedules.map((d) => {
          return {
            date: d.date,
            time: d.times,
          }
        }),
      })
      .then(() => {
        toast.success("Berhasil Tambah Event")
        this.props.history.push("/admin/schedule/event")
      })
  }

  getEvent() {
    this.props.getEvent(this.props.match.params.eventId).then(() => {
      const detail = this.props.event.detail
      this.setState({
        dateDisabled: true,
        schedules: detail.EventDates.map((date) => {
          return {
            date: date.date,
            times: date.EventDateTimes.map((time) => {
              return time.time
            }),
          }
        }),

        name: detail.name,
        description: detail.description,
        churchId: detail.churchId,
        placeValue: {
          label: detail.place_name,
          value: detail.churchId ? detail.churchId : detail.place_name,
        },
        address: detail.address,
        capacity: detail.capacity,
        place_name: detail.place_name,
        isModalShow: false,
        showImage: detail.image,
        is_bookable: detail.is_bookable ? true : false,
      })
    })
  }

  handleDelete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteEvent(this.props.match.params.eventId).then(() => {
          Swal.fire("Deleted!", "Schedule has been deleted.", "success")
          this.props.history.push(`/admin/schedule/event`)
        })
      }
    })
  }

  componentDidMount() {
    this.getChurches()
    if (this.state.readOnly) {
      this.getEvent()
    }
  }

  getChurches() {
    this.props.getChurches(this.state.params)
  }

  get places() {
    return this.props.church.data.map((church) => {
      return {
        label: church.name,
        value: church.id,
        data: church,
      }
    })
  }

  updateEvent() {
    this.props
      .updateEvent(this.props.match.params.eventId, {
        name: this.state.name,
        place_name: this.state.place_name,
        capacity: String(this.state.capacity),
        image: this.state.image ? this.state.image : undefined,
        description: this.state.description,
        church_id: this.state.churchId,
        address: this.state.address,
        is_bookable: this.state.is_bookable ? "1" : "0",
        type: "2",
        schedules: this.state.schedules.map((d) => {
          return {
            date: d.date,
            time: d.times,
          }
        }),
      })
      .then(() => {
        toast.success("Berhasil Edit Event")
        this.getEvent()

        this.setState({
          readOnly: true,
        })
      })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (this.props.match.params.eventId === "create") {
      return this.addEvent()
    }

    return this.updateEvent()
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/schedule/event">Schedule</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/admin/schedule/event">Event</Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Tambah Schedule</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <h1 className="mb-4">Detail Event</h1>
              <Row>
                <Col xl={8}>
                  <Form
                    onSubmit={this.proxySubmit.bind(this)}
                    id="create-event"
                  >
                    <FormGroup>
                      <Label htmlFor="schedule">Nama Event</Label>
                      <Input
                        readOnly={this.state.readOnly}
                        type="text"
                        name="schedul:e"
                        id="schedule"
                        placeholder="Masukan Nama Event"
                        onChange={(e) => {
                          this.setState({
                            name: e.target.value,
                          })
                        }}
                        value={this.state.name}
                      />
                    </FormGroup>

                    <Row>
                      <Col xl={10}>
                        <FormGroup>
                          <Label htmlFor="description">Deskripsi</Label>
                          <Input
                            type="textarea"
                            name="description"
                            id="description"
                            placeholder="Masukan Deskripsi Event"
                            onChange={(e) => {
                              this.setState({
                                description: e.target.value,
                              })
                            }}
                            value={this.state.description}
                            readOnly={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label htmlFor="exampleSelect">Tempat</Label>
                      <CreatableSelect
                        isClearable
                        isDisabled={this.state.readOnly}
                        onChange={(e) => {
                          if (e["__isNew__"]) {
                            this.setState({
                              placeValue: e,
                              churchId: null,
                              place_name: e.label,
                            })
                          } else {
                            this.setState({
                              placeValue: e,
                              churchId: e.value,
                              place_name: e.label,
                              address: e.data.address,
                              capacity: e.data.capacity,
                            })
                          }
                        }}
                        options={this.places}
                        placeholder="Pilih atau ketik nama tempat"
                        value={this.state.placeValue}
                      />
                    </FormGroup>

                    <Row>
                      <Col xl={10}>
                        <FormGroup>
                          <Label htmlFor="address">Alamat</Label>
                          <Input
                            type="textarea"
                            name="address"
                            id="address"
                            placeholder="Masukan Alamat Lengkap"
                            onChange={(e) => {
                              this.setState({
                                address: e.target.value,
                              })
                            }}
                            value={this.state.address}
                            readOnly={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={10}>
                        <FormGroup>
                          <Label htmlFor="address">Kapasitas</Label>
                          <Input
                            type="number"
                            name="capacity"
                            id="capacity"
                            placeholder="Kapasitas Acara"
                            onChange={(e) => {
                              this.setState({
                                capacity: e.target.value,
                              })
                            }}
                            value={this.state.capacity}
                            readOnly={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label htmlFor="image">Gambar</Label>
                      <Row>
                        <Col xl={10}>
                          <File
                            onChange={(e) => {
                              if (!e.target.files.length) {
                                return
                              }

                              let reader = new FileReader()
                              reader.readAsDataURL(e.target.files[0])
                              reader.onload = () => {
                                this.setState({
                                  image: reader.result,
                                  showImage: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                  file_name: e.target.files[0].name,
                                })
                              }
                              reader.onerror = function (error) {
                                console.log("Error: ", error)
                              }
                            }}
                            accept="image/png, image/gif, image/jpeg"
                            disabled={this.state.readOnly}
                            className="mb-2"
                          />
                        </Col>

                        <Col xl={2}>
                          <button
                            // color="primary"
                            className="btn btn-link"
                            type="button"
                            onClick={this.modalToggle.bind(this)}
                            disabled={!this.state.showImage}
                          >
                            Tampilkan
                          </button>
                        </Col>
                      </Row>

                      <span className="mt-2">
                        dimensi gambar 16:9, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <div class="form-check">
                        <Input
                          type="checkbox"
                          value=""
                          id="isBookable"
                          onChange={(e) =>
                            this.setState({ is_bookable: e.target.checked })
                          }
                          checked={this.state.is_bookable}
                          disabled={this.state.readOnly}
                        />
                        <Label for="isBookable">Bisa dipesan Online?</Label>
                      </div>
                    </FormGroup>

                    <hr />

                    <h1 className="mb-4">Tambah Schedule</h1>

                    <FormGroup>
                      <Label htmlFor="date">Pilih Tanggal</Label>
                      <Row>
                        <Col xl={10}>
                          <Input
                            type="date"
                            name="date"
                            id="date"
                            onChange={(e) =>
                              this.setState({
                                date: e.target.value,
                              })
                            }
                            value={this.state.date}
                            readOnly={this.state.readOnly}
                          />
                        </Col>
                      </Row>
                      <span>
                        Hanya dapat memilih satu tanggal saat membuat schedule
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="time">Pilih Waktu</Label>
                      <Row>
                        <Col xl={10}>
                          <Input
                            type="time"
                            name="time"
                            id="time"
                            onChange={(e) =>
                              this.setState({
                                time: e.target.value,
                              })
                            }
                            value={this.state.time}
                            readOnly={this.state.readOnly}
                          />
                        </Col>
                        <Col xl={2}>
                          <Button
                            color="primary"
                            type="button"
                            onClick={this.addSchedules.bind(this)}
                            disabled={this.state.readOnly}
                          >
                            Tambah
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>

                    <hr />

                    <h1 className="mb-4">Waktu yang dipilih</h1>

                    <div className="d-flex flex-column">
                      {this.state.schedules.map((schedule, i) => (
                        <>
                          <p>
                            {moment(schedule.date).format("dddd, DD MMMM YYYY")}
                          </p>
                          {schedule.times.map((time, o) => {
                            return (
                              <div className="d-flex flex-row align-items-center mb-3">
                                <div className="time-badge mr-3">{time}</div>
                                <button
                                  type="button"
                                  className="btn-link"
                                  onClick={() => {
                                    this.deleteSchedule(i, o)
                                  }}
                                  disabled={this.state.readOnly}
                                >
                                  Hapus
                                </button>
                              </div>
                            )
                          })}
                        </>
                      ))}
                    </div>
                  </Form>
                </Col>
              </Row>
              <Container className="d-flex">
                <div className="d-flex flex-row align-items-center mb-3 ml-auto">
                  {this.state.readOnly ? (
                    <>
                      <Button
                        color="primary"
                        outline
                        onClick={this.handleDelete.bind(this)}
                      >
                        Hapus
                      </Button>
                      <div>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            this.setState({
                              readOnly: !this.state.readOnly,
                            })
                          }}
                        >
                          Edit Schedule
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        outline
                        onClick={this.goBack.bind(this)}
                      >
                        Batal
                      </Button>
                      <Button color="primary" type="submit" form="create-event">
                        Simpan
                      </Button>
                    </>
                  )}
                </div>
              </Container>
            </CardBody>
          </Card>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    church: state.church,
    event: state.event,
  }
}

const mapDispatchToProps = {
  getChurches: church.getChurches,
  addEvent: event.addEvent,
  getEvent: event.getEvent,
  deleteEvent: event.deleteEvent,
  updateEvent: event.updateEvent,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateEventSchedule)
