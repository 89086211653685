import getEvents from "./getEvents"
import getEventsNe from "./getEventsNe"
import nextEvents from "./nextEvents"
import getEvent from "./getEvent"
import addEvent from "./addEvent"
import deleteEvent from "./deleteEvent"
import updateEvent from "./updateEvent"

export { getEvents, nextEvents, getEvent, deleteEvent, getEventsNe }

const event = {
  getEvents,
  nextEvents,
  getEvent,
  addEvent,
  deleteEvent,
  updateEvent,
  getEventsNe,
}

export default event
