import { axiosInstance } from "../../../helper"
import { magazine } from "../types"

export function getMagazine(params, config) {
  return {
    type: magazine.GET_MAGAZINE,
    payload: new axiosInstance.get("/admin/weekly-magazine", {
      ...config,
      params,
    }),
  }
}

export default getMagazine
