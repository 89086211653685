import { toast } from "react-toastify"
import { promiseState } from "../../helper"
import { event } from "../../redux/actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
  isLoading: false,
  errors: [],
  hasMore: false,
  message: "",
  data: [],
  page: {
    totalPage: 0,
    currentPage: 1,
    totalData: 0,
  },
  detail: {},
}

function eventReducer(state = initialState, action) {
  switch (action.type) {
    case pendingState(event.GET_EVENTS): {
      return Object.assign({}, state, {
        isLoading: true,
        hasMore: true,
      })
    }

    case rejectedState(event.GET_EVENTS): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(event.GET_EVENTS): {
      const hasMore = action.payload.data.page
        ? action.payload.data.data.length < action.payload.data.page.totalData
        : false
      return Object.assign({}, state, {
        isLoading: false,
        data: action.payload.data.data,
        hasMore,
        page: {
          totalPage: action.payload.data.page
            ? action.payload.data.page.totalPage
            : 1,
          currentPage: action.payload.data.page
            ? action.payload.data.page.currentPage
            : 1,
          totalData: action.payload.data.page
            ? action.payload.data.page.totalData
            : action.payload.data.data.length,
        },
      })
    }

    case pendingState(event.DETAIL_EVENT): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(event.DETAIL_EVENT): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(event.DETAIL_EVENT): {
      return Object.assign({}, state, {
        isLoading: false,
        detail: action.payload.data.data,
      })
    }

    case pendingState(event.NEXT_EVENTS): {
      return Object.assign({}, state, {
        hasMore: true,
        isLoading: true,
      })
    }

    case rejectedState(event.NEXT_EVENTS): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(event.NEXT_EVENTS): {
      const hasMore =
        state.data.concat(action.payload.data.data).length <
        action.payload.data.page.totalData
      return Object.assign({}, state, {
        isLoading: false,
        data: state.data.concat(action.payload.data.data),
        hasMore,
        page: {
          totalPage: action.payload.data.page.totalPage,
          currentPage: action.payload.data.page.currentPage,
          totalData: action.payload.data.page.totalData,
        },
      })
    }

    case pendingState(event.ADD_EVENT): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(event.ADD_EVENT): {
      toast.error(
        action?.payload?.response
          ? action?.payload?.response.data.message
          : action?.payload?.message
      )
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(event.ADD_EVENT): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }
    default:
      return state
  }
}

export default eventReducer
