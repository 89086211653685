import { Component } from "react"
import { connect } from "react-redux"
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Nav,
  NavLink,
  NavItem,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import { NavLink as NavLinkRRD } from "react-router-dom"
import { banner } from "../../../redux/actions"
import Swal from "sweetalert2"
import { Icon } from "@iconify/react"
import { toast } from "react-toastify"

class EventBanner extends Component {
  getEventBanner() {
    this.props.getEventBanner()
  }

  deleteEventBanner(id) {
    this.props.deleteEventBanner(id).then(() => {
      toast.success("Berhasil Hapus Banner")
      this.getEventBanner()
    })
  }

  updateEventBanner(id, data) {
    this.props.updateEventBanner(id, data).then(() => {
      toast.success("Update Banner Berhasil")
      this.getEventBanner()

      this.forceUpdate()
    })
  }

  handleDelete(id, type) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        return this.deleteEventBanner(id)
      }
    })
  }

  componentDidMount() {
    this.getEventBanner()
  }

  render() {
    return (
      <Container fluid className="mb-5">
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Banner</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="schedule-nav mb-3">
          <Nav>
            <NavItem>
              <NavLink tag={NavLinkRRD} to="/admin/new-banner/information">
                Banner Informasi
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={NavLinkRRD} to="/admin/new-banner/event">
                Banner Event
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <p>*Maksimal upload 10 Banner</p>

        <div className="d-flex justify-content-end">
          {/* Page content */}
          <Button
            color="primary"
            disabled={this.props.banner.eventData.length >= 10}
            onClick={() => {
              this.props.history.push("/admin/new-banner/event/create")
            }}
          >
            Tambah Banner
          </Button>
        </div>

        <Row className="mb-4 mt-3">
          {this.props.banner.eventData.map((info) => (
            <Col xl={4}>
              <Card className="mb-3 banner">
                <div className="card-img-top">
                  <img src={info.image} alt={info.image} width="100%" />
                  <div
                    className="del-card"
                    onClick={() => {
                      this.handleDelete(info.id, "info")
                    }}
                  >
                    <Icon icon="akar-icons:circle-x-fill" fontSize={"35px"} />
                  </div>
                </div>
                <CardBody className="d-flex justify-content-between">
                  <h5>{info.Event ? info.Event.name : "Lainnya"}</h5>
                  <div className="d-flex">
                    <select
                      className="form-control form-control-sm ml-auto"
                      defaultValue={info.index}
                      value={info.index}
                      onChange={(e) => {
                        const formData = new FormData()
                        formData.append("index", e.target.value)
                        if (info.event_id) {
                          formData.append("event_id", info.event_id)
                        }

                        this.updateEventBanner(info.id, formData)
                      }}
                    >
                      {Array(this.props.banner.eventData.length)
                        .fill(1)
                        .map((el, i) => (
                          <option value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    banner: state.banner,
  }
}

const mapDispatchToProps = {
  getEventBanner: banner.getEventBanner,
  deleteEventBanner: banner.deleteEventBanner,
  updateEventBanner: banner.updateEventBanner,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventBanner)
