import { axiosInstance } from "../../../helper"
import { sermon } from "../types"

export function addItem(body, config) {
  return {
    type: sermon.ADD,
    payload: new axiosInstance.post(`/admin/sermon/`, body, config),
  }
}

export default addItem
