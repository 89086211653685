import { toast } from "react-toastify"
import { promiseState } from "../../helper"
import { sermon } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
  isLoading: false,
  message: null,
  errors: [],
  data: [],
  detail: {},
  categories: [],
  page: {
    totalPage: 0,
    currentPage: 1,
    totalData: 0,
  },
}

const sermonReducer = (state = initialState, action) => {
  switch (action.type) {
    case pendingState(sermon.GET): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(sermon.GET): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(sermon.GET): {
      return Object.assign({}, state, {
        isLoading: false,
        data: action.payload.data.data,
      })
    }

    case pendingState(sermon.GET_CATEGORIES): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(sermon.GET_CATEGORIES): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(sermon.GET_CATEGORIES): {
      return Object.assign({}, state, {
        isLoading: false,
        categories: action.payload.data.data,
      })
    }

    case pendingState(sermon.GET_DETAIL): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(sermon.GET_DETAIL): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(sermon.GET_DETAIL): {
      return Object.assign({}, state, {
        isLoading: false,
        detail: action.payload.data.data,
      })
    }

    case rejectedState(sermon.ADD): {
      toast.error(
        action.payload.response
          ? action.payload.response.data.message
          : action.payload.message
      )
      return Object.assign({}, state, {
        message: action.payload.response
          ? action.payload.response.data.message
          : action.payload.message,
      })
    }

    case rejectedState(sermon.UPDATE): {
      toast.error(
        action.payload.response
          ? action.payload.response.data.message
          : action.payload.message
      )
      return Object.assign({}, state, {
        message: action.payload.response
          ? action.payload.response.data.message
          : action.payload.message,
      })
    }

    case rejectedState(sermon.DELETE): {
      toast.error(
        action.payload.response
          ? action.payload.response.data.message
          : action.payload.message
      )
      return Object.assign({}, state, {
        message: action.payload.response
          ? action.payload.response.data.message
          : action.payload.message,
      })
    }

    default:
      return state
  }
}

export default sermonReducer
