import { Icon } from "@iconify/react"
import Index from "./views/Dashboard"
import { Account, CreateAccount } from "./views/glowfc/account"
import { Church, CreateChurch } from "./views/glowfc/church"
import { User, DetailUser } from "./views/glowfc/user"
import {
  Schedule,
  ScheduleEvent,
  Worship,
  RedirectSchedule,
  CreateEventSchedule,
  CreateWorshipSchedule,
} from "./views/glowfc/schedule"
import { Login } from "./views/glowfc/auth"
import { StreamingVideo, CreateVideo } from "./views/glowfc/streaming"
import {
  // Banner,
  // CreateBanner,
  CreateEventBanner,
  CreateInformationBanner,
  EventBanner,
  InformationBanner,
  RedirectBanner,
} from "./views/glowfc/banner"
import {
  Sacrament,
  Baptism,
  HolyMatrimony,
  ChildSubmission,
  BaptismDetail,
  ChildSubmissionDetail,
  MatrimonialDetail,
} from "./views/glowfc/sacrament"
import { Resource, CreateResource } from "./views/glowfc/resource"
import { Secretariat, CreateSecretariat } from "./views/glowfc/secretariat"
import { Ministry, UpdateMinistryContent } from "./views/glowfc/ministry"
import { ChurchRecord, DetailRecord } from "./views/glowfc/church-record"
import {
  CreateOurLeader,
  CreateWeeklyMagz,
  ImageSystem,
  OurLeader,
  PopupImage,
  WeeklyMagz,
} from "./views/glowfc/content-management"
import { CreateSermon, Sermon } from "./views/glowfc/sermon"

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: (
      <Icon
        icon="ic:baseline-space-dashboard"
        className="mr-3"
        fontSize={"18px"}
      />
    ),
    component: Index,
    layout: "/admin",
  },

  {
    path: "/schedule",
    name: "Schedule",
    icon: (
      <Icon icon="bi:calendar-check-fill" className="mr-3" fontSize={"18px"} />
    ),
    component: RedirectSchedule,
    layout: "/admin",
  },

  {
    path: "/schedule/worship",
    sidebarHide: true,
    name: "Schedule",
    icon: (
      <Icon icon="bi:calendar-check-fill" className="mr-3" fontSize={"18px"} />
    ),
    component: Schedule,
    layout: "/admin",
  },

  {
    path: "/schedule/worship/:churchId",
    sidebarHide: true,
    name: "Schedule",
    icon: (
      <Icon icon="bi:calendar-check-fill" className="mr-3" fontSize={"18px"} />
    ),
    component: Worship,
    layout: "/admin",
  },

  {
    path: "/schedule/worship/:churchId/:timeId",
    sidebarHide: true,
    name: "Schedule",
    icon: (
      <Icon icon="bi:calendar-check-fill" className="mr-3" fontSize={"18px"} />
    ),
    component: CreateWorshipSchedule,
    layout: "/admin",
  },

  {
    path: "/schedule/event",
    name: "Schedule Event",
    icon: (
      <Icon icon="bi:calendar-check-fill" className="mr-3" fontSize={"18px"} />
    ),
    component: ScheduleEvent,
    layout: "/admin",
    sidebarHide: true,
  },

  {
    path: "/schedule/event/:eventId",
    sidebarHide: true,
    icon: (
      <Icon icon="bi:calendar-check-fill" className="mr-3" fontSize={"18px"} />
    ),
    component: CreateEventSchedule,
    layout: "/admin",
  },

  {
    path: "/sacrament",
    name: "Sakramen",
    icon: (
      <Icon icon="ant-design:file-filled" className="mr-3" fontSize={"18px"} />
    ),
    component: Sacrament,
    layout: "/admin",
  },

  {
    path: "/sacrament/baptism",
    sidebarHide: true,
    component: Baptism,
    layout: "/admin",
  },

  {
    path: "/sacrament/baptism/:baptismId",
    sidebarHide: true,
    component: BaptismDetail,
    layout: "/admin",
  },

  {
    path: "/sacrament/holy-matrimony",
    sidebarHide: true,
    component: HolyMatrimony,
    layout: "/admin",
  },

  {
    path: "/sacrament/holy-matrimony/:matrimonyId",
    sidebarHide: true,
    component: MatrimonialDetail,
    layout: "/admin",
  },

  {
    path: "/sacrament/child-submission",
    sidebarHide: true,
    component: ChildSubmission,
    layout: "/admin",
  },

  {
    path: "/sacrament/child-submission/:childId",
    sidebarHide: true,
    component: ChildSubmissionDetail,
    layout: "/admin",
  },

  {
    path: "/new-banner",
    name: "Banner",
    icon: (
      <Icon icon="ion:information-circle" className="mr-3" fontSize={"18px"} />
    ),
    component: RedirectBanner,
    layout: "/admin",
  },

  {
    path: "/new-banner/information",
    sidebarHide: true,
    name: "Banner Information",
    icon: (
      <Icon icon="bi:calendar-check-fill" className="mr-3" fontSize={"18px"} />
    ),
    component: InformationBanner,
    layout: "/admin",
  },

  {
    path: "/new-banner/information/create",
    sidebarHide: true,
    component: CreateInformationBanner,
    layout: "/admin",
  },

  {
    path: "/new-banner/event",
    sidebarHide: true,
    name: "Banner Event",
    component: EventBanner,
    layout: "/admin",
  },

  {
    path: "/new-banner/event/create",
    sidebarHide: true,
    component: CreateEventBanner,
    layout: "/admin",
  },

  // {
  //   path: "/banner",
  //   name: "Banner & Informasi",
  //   icon: (
  //     <Icon icon="akar-icons:newspaper" className="mr-3" fontSize={"18px"} />
  //   ),
  //   component: Banner,
  //   layout: "/admin",
  // },

  // {
  //   path: "/banner/create",
  //   sidebarHide: true,
  //   component: CreateBanner,
  //   layout: "/admin",
  // },

  {
    path: "/resource",
    name: "Resource",
    icon: <Icon icon="bxs:book-alt" className="mr-3" fontSize={"18px"} />,
    component: Resource,
    layout: "/admin",
  },

  {
    path: "/resource/:resourceId",
    sidebarHide: true,
    component: CreateResource,
    layout: "/admin",
  },

  {
    path: "/secretariat",
    name: "Sekretariat",
    icon: (
      <Icon icon="dashicons:businessman" className="mr-3" fontSize={"18px"} />
    ),
    component: Secretariat,
    layout: "/admin",
  },

  {
    path: "/secretariat/:secretariatId",
    sidebarHide: true,
    component: CreateSecretariat,
    layout: "/admin",
  },

  {
    path: "/video-resource",
    name: "Video Resources",
    icon: (
      <Icon icon="entypo:folder-video" className="mr-3" fontSize={"18px"} />
    ),
    component: StreamingVideo,
    layout: "/admin",
  },

  {
    path: "/video-resource/:videoId",
    sidebarHide: true,
    component: CreateVideo,
    layout: "/admin",
  },

  {
    path: "/ministry",
    name: "Ministry",
    icon: (
      <Icon
        icon="medical-icon:social-services"
        className="mr-3"
        fontSize={"18px"}
      />
    ),
    component: Ministry,
    layout: "/admin",
  },

  {
    path: "/ministry/:categoryId/content",
    sidebarHide: true,
    component: UpdateMinistryContent,
    layout: "/admin",
  },

  {
    path: "/content-management",
    collapseKey: "content-management",
    name: "Managemen Konten",
    icon: (
      <Icon
        icon="fluent:content-settings-16-filled"
        className="mr-3"
        fontSize={"18px"}
      />
    ),
    layout: "/admin",
    collapse: true,
    collapseItems: [
      {
        path: "/image-system",
        name: "Image System",
        component: ImageSystem,
        layout: "/admin",
      },

      {
        path: "/weekly-magz",
        name: "Majalah Migguan",
        component: WeeklyMagz,
        layout: "/admin",
      },

      {
        path: "/our-leader",
        name: "Our Leader & Wakil Gembala",
        component: OurLeader,
        layout: "/admin",
      },

      {
        path: "/popup-image",
        name: "Gambar Pop-up",
        component: PopupImage,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/sermon",
    name: "Khotbah",
    icon: (
      <Icon icon="carbon:user-speaker" className="mr-3" fontSize={"18px"} />
    ),
    component: Sermon,
    layout: "/admin",
  },

  {
    path: "/sermon/:sermonId",
    sidebarHide: true,
    component: CreateSermon,
    layout: "/admin",
  },

  {
    path: "/content-management/weekly-magz/:magzId",
    sidebarHide: true,
    component: CreateWeeklyMagz,
    layout: "/admin",
  },

  {
    path: "/content-management/our-leader/:leaderId",
    sidebarHide: true,
    component: CreateOurLeader,
    layout: "/admin",
  },

  // {
  // 	path: "/donation",
  // 	name: "Support & Give",
  // 	icon: <Icon icon='ri:service-fill' className='mr-3' fontSize={"18px"} />,
  // 	component: NotFound,
  // 	layout: "/admin",
  // },

  {
    path: "/user",
    name: "Data User",
    icon: (
      <Icon icon="fa-solid:user-friends" className="mr-3" fontSize={"18px"} />
    ),
    component: User,
    layout: "/admin",
  },

  {
    path: "/user/:userId",
    name: "Data User",
    sidebarHide: true,
    component: DetailUser,
    layout: "/admin",
  },

  {
    path: "/church-record",
    name: "Pendataan Jemaat",
    icon: (
      <Icon
        icon="fluent:people-edit-20-filled"
        className="mr-3"
        fontSize={"18px"}
      />
    ),
    component: ChurchRecord,
    layout: "/admin",
  },

  {
    path: "/church-record/:recordId",
    sidebarHide: true,
    component: DetailRecord,
    layout: "/admin",
  },

  {
    path: "/account",
    name: "Manajemen Akun",
    icon: <Icon icon="mdi:account-cog" className="mr-3" fontSize={"18px"} />,
    component: Account,
    layout: "/admin",
  },

  {
    path: "/account/:userId",
    icon: <i className="fas fa-user"></i>,
    component: CreateAccount,
    layout: "/admin",
    sidebarHide: true,
  },

  {
    path: "/church",
    name: "Data Gereja",
    icon: (
      <Icon
        icon="material-symbols:church-rounded"
        className="mr-3"
        fontSize={"18px"}
      />
    ),
    component: Church,
    layout: "/admin",
  },

  {
    path: "/church/:churchId",
    icon: <i className="fas fa-user"></i>,
    component: CreateChurch,
    layout: "/admin",
    sidebarHide: true,
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
]
export default routes
