import { axiosInstance } from "../../../helper"
import { magazine } from "../types"

export function deleteMagazine(id, config) {
  return {
    type: magazine.DELETE_MAGAZINE,
    payload: new axiosInstance.delete(`/admin/weekly-magazine/${id}`, config),
  }
}

export default deleteMagazine
