import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import Joi from "joi"
import { church } from "../../../redux/actions"
import { connect } from "react-redux"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { File } from "./../../../components/Form"

class CreateChurch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: {
        value: "",
        rule: Joi.string().required(),
        valid: true,
        errMsg: "",
      },

      address: {
        value: "",
        rule: Joi.string().required(),
        valid: true,
        errMsg: "",
      },

      description: {
        value: "",
        rule: Joi.string().required(),
        valid: true,
        errMsg: "",
      },

      maps_url: {
        value: "",
        rule: Joi.string().required(),
        valid: true,
        errMsg: "",
      },

      capacity: {
        value: "",
        rule: Joi.string().alphanum().required(),
        valid: true,
        errMsg: "",
      },

      image: {
        value: null,
        valid: true,
        errMsg: "",
        rule: {
          validate(param) {
            return {
              value: param,
            }
          },
        },
      },

      church_category_id: {
        value: "",
        valid: true,
        errMsg: "",
        rule: Joi.string().alphanum().required(),
      },

      churchId: this.props.match.params.churchId,

      isModalShow: false,
      disable: this.props.match.params.churchId !== "create",
      showImage: null,
    }
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  inputOnChange(name, value) {
    const fieldProperties = { ...this.state }
    const isValid = fieldProperties[name].rule.validate(value)

    fieldProperties[name].valid = !isValid.error
    fieldProperties[name].value = value

    if (isValid.error) {
      fieldProperties[name].errMsg = isValid.error.message
    }

    this.setState(fieldProperties)
  }

  goBack() {
    if (this.props.match.params.churchId === "create") {
      this.props.history.push("/admin/church")
    }

    return this.setState({
      disable: !this.state.disable,
    })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (this.state.churchId === "create") {
      return this.addChurch()
    } else {
      return this.updateChurch()
    }
  }

  handleDelete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteChurch(this.props.match.params.churchId).then(() => {
          Swal.fire("Deleted!", "Church has been deleted.", "success")
          this.props.history.push(`/admin/church`)
        })
      }
    })
  }

  updateChurch() {
    const formData = new FormData()

    formData.append("name", this.state.name.value)
    formData.append("description", this.state.description.value)
    formData.append("capacity", this.state.capacity.value)
    formData.append("address", this.state.address.value)
    formData.append("location_url", this.state.maps_url.value)
    formData.append("church_category_id", this.state.church_category_id.value)

    if (this.state.image.value) {
      formData.append("image", this.state.image.value[0])
    }

    this.props
      .updateChurch(this.props.match.params.churchId, formData)
      .then(() => {
        toast.success("Berhasil Edit Gereja")
        this.getChurch()

        this.setState({
          disable: true,
        })
      })
  }

  addChurch() {
    const formData = new FormData()

    formData.append("name", this.state.name.value)
    formData.append("description", this.state.description.value)
    formData.append("capacity", this.state.capacity.value)
    formData.append("address", this.state.address.value)
    formData.append("location_url", this.state.maps_url.value)
    formData.append("church_category_id", this.state.church_category_id.value)
    if (this.state.image.value) {
      formData.append("image", this.state.image.value[0])
    }
    this.props.addChurch(formData).then(() => {
      toast.success("Tambah Gereja Berhasil")

      this.props.history.push("/admin/church")
    })
  }

  getChurch() {
    this.props.getChurch(this.props.match.params.churchId).then(() => {
      const fieldProperties = { ...this.state }
      fieldProperties.name.value = this.props.church.detail.name
      fieldProperties.address.value = this.props.church.detail.address
      fieldProperties.capacity.value = this.props.church.detail.capacity
      fieldProperties.description.value = this.props.church.detail.description
      fieldProperties.maps_url.value = this.props.church.detail.location_url
      fieldProperties.church_category_id.value =
        this.props.church.detail.church_category_id
      fieldProperties.showImage = this.props.church.detail.image

      this.setState(fieldProperties)
    })
  }

  componentDidMount() {
    if (this.props.match.params.churchId !== "create") {
      this.getChurch()
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/church">Branch List</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Tambah Branch</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h1 className="mb-4">Detail Branch</h1>
                  <Row>
                    <Col xl={8}>
                      <Form
                        onSubmit={this.proxySubmit.bind(this)}
                        id="create-church-form"
                      >
                        <FormGroup>
                          <Label htmlFor="name">Nama Branch</Label>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Masukan Nama Branch"
                            onChange={(e) =>
                              this.inputOnChange("name", e.target.value)
                            }
                            invalid={!this.state.name.valid}
                            value={this.state.name.value}
                            disabled={this.state.disable}
                          />
                          <FormFeedback>{this.state.name.errMsg}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="image">Gambar</Label>
                          <Row>
                            <Col xl={10}>
                              <File
                                onChange={(e) => {
                                  this.inputOnChange("image", e.target.files)
                                  this.setState({
                                    showImage: URL.createObjectURL(
                                      e.target.files[0]
                                    ),
                                  })
                                }}
                                accept="image/png, image/gif, image/jpeg"
                                disabled={this.state.disable}
                              />
                            </Col>

                            <Col xl={2}>
                              <button
                                // color="link"
                                className="btn btn-link"
                                type="button"
                                onClick={this.modalToggle.bind(this)}
                                disabled={!this.state.showImage}
                              >
                                Tampilkan
                              </button>
                            </Col>
                          </Row>

                          <span className="mt-2">
                            dimensi gambar 1:2, ukuran gambar 5MB, format gambar
                            (.jpg & .png)
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="maps_url">Lokasi Branch</Label>
                          <Input
                            type="text"
                            name="maps_url"
                            id="maps_url"
                            placeholder="Masukan url Google Maps"
                            onChange={(e) =>
                              this.inputOnChange("maps_url", e.target.value)
                            }
                            invalid={!this.state.maps_url.valid}
                            value={this.state.maps_url.value}
                            disabled={this.state.disable}
                          />
                          <FormFeedback>
                            {this.state.maps_url.errMsg}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="address">Alamat Branch</Label>
                          <Input
                            type="textarea"
                            name="address"
                            id="address"
                            placeholder="Masukan Alamat Lengkap"
                            onChange={(e) =>
                              this.inputOnChange("address", e.target.value)
                            }
                            invalid={!this.state.address.valid}
                            value={this.state.address.value}
                            disabled={this.state.disable}
                          />
                          <FormFeedback>
                            {this.state.address.errMsg}
                          </FormFeedback>
                        </FormGroup>

                        <Row>
                          <Col xl={4}>
                            <FormGroup>
                              <Label htmlFor="capacity">Kapasitas</Label>
                              <Input
                                type="number"
                                name="capacity"
                                id="capacity"
                                placeholder="Masukan Kapasitas"
                                onChange={(e) =>
                                  this.inputOnChange("capacity", e.target.value)
                                }
                                invalid={!this.state.capacity.valid}
                                value={this.state.capacity.value}
                                disabled={this.state.disable}
                              />
                              <FormFeedback>
                                {this.state.capacity.errMsg}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup>
                          <Label htmlFor="description">Deskripsi</Label>
                          <Input
                            id="description"
                            name="description"
                            type="textarea"
                            placeholder="Masukan Deskripsi"
                            onChange={(e) =>
                              this.inputOnChange("description", e.target.value)
                            }
                            invalid={!this.state.description.valid}
                            value={this.state.description.value}
                            disabled={this.state.disable}
                          />
                          <FormFeedback>
                            {this.state.description.errMsg}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="name">Kategori Branch</Label>
                          <Input
                            type="select"
                            onChange={(e) =>
                              this.inputOnChange(
                                "church_category_id",
                                e.target.value
                              )
                            }
                            value={this.state.church_category_id.value}
                            disabled={this.state.disable}
                          >
                            <option value="" selected disabled>
                              Pilih Kategori
                            </option>
                            <option value="1">Utama</option>
                            <option value="2">Cabang</option>
                          </Input>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                  <Container className="d-flex">
                    <div className="d-flex flex-row align-items-center mb-3 ml-auto">
                      {this.state.disable ? (
                        <>
                          <Button
                            color="primary"
                            outline
                            onClick={this.handleDelete.bind(this)}
                          >
                            Hapus
                          </Button>
                          <div>
                            <Button
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.setState({
                                  disable: !this.state.disable,
                                })
                              }}
                            >
                              Edit Gereja
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <Button
                            color="primary"
                            outline
                            onClick={this.goBack.bind(this)}
                          >
                            Batal
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            form="create-church-form"
                          >
                            Simpan
                          </Button>
                        </>
                      )}
                    </div>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    church: state.church,
  }
}

const mapDispatchToProps = {
  addChurch: church.addChurch,
  updateChurch: church.updateChurch,
  getChurch: church.getChurch,
  deleteChurch: church.deleteChurch,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateChurch)
