import {
  Card,
  Label,
  Input,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Nav,
  NavItem,
  NavLink,
  Col,
  CardImg,
  CardBody,
  FormGroup,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link, NavLink as NavLinkRRD } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { connect } from "react-redux"
import { event, video } from "../../../redux/actions"
import { getColorPercent } from "../../../helper"
class ScheduleEvent extends Component {
  constructor() {
    super()

    this.state = {
      data: [
        {
          title: 0,
        },
      ],
      hasMore: true,

      params: {
        limit: 10,
        search: "",
        page: 1,
      },
    }
  }

  fetchData(e) {
    this.props.nextEvents({
      page: this.props.event.page.currentPage + 1,
      limit: this.state.params.limit,
    })
  }

  countQuota(
    arr = [
      {
        reservation_quota: 0,
      },
    ]
  ) {
    let sum = 0

    for (let index = 0; index < arr.length; index++) {
      sum += arr[index].reservation_quota
    }

    return sum
  }

  getEvents() {
    this.props.getEvents(this.state.params)
  }

  componentDidMount() {
    this.getEvents()
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem active>Schedule</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <div className="schedule-nav mb-3">
            <Nav>
              <NavItem>
                <NavLink tag={NavLinkRRD} to="/admin/schedule/worship">
                  Ibadah Minggu
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NavLinkRRD} to="/admin/schedule/event" active>
                  Event
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <Row>
            <Col xl={8} className="mb-3">
              <Row>
                <Col xl={8}>
                  <FormGroup>
                    <Label htmlFor="">Cari Event</Label>
                    <Input
                      type="text"
                      className="form-control form-search"
                      placeholder="Cari berdasarkan nama tempat"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.getEvents()
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          params: Object.assign({}, this.state.params, {
                            search: e.target.value,
                          }),
                        })
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xl={4}>
                  <div>
                    <Label htmlFor="dateFilter">Filter Tanggal</Label>
                    <Input
                      id="dateFilter"
                      name="dateFilter"
                      type="date"
                      onChange={(e) => {
                        this.setState(
                          {
                            params: Object.assign({}, this.state.params, {
                              date: e.target.value,
                            }),
                          },
                          () => {
                            this.getEvents()
                          }
                        )
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xl={4} className="mb-3">
              <div className="d-flex">
                <div className="ml-auto">
                  <Link
                    to={{
                      pathname: "/admin/schedule/event/create",
                    }}
                    className="btn btn-md btn-primary"
                  >
                    Tambah Event
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <InfiniteScroll
            className="row mb-4 card-event"
            dataLength={this.state.data.length} //This is important field to render the next data
            next={this.fetchData.bind(this)}
            hasMore={this.props.event.hasMore}
            loader={
              <Col xl={12}>
                <p style={{ textAlign: "center" }}>
                  <b>Loading...</b>
                </p>
              </Col>
            }
            endMessage={
              <Col xl={12}>
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              </Col>
            }
          >
            {this.props.event.data.map((v) => (
              <Col xl={4} className="mb-3">
                <Card>
                  <CardImg top width="100%" src={v.image} alt={v.image} />
                  <CardBody>
                    <h5>{v.name}</h5>
                    <p>{v.address}</p>

                    <h3>Time</h3>
                    {v.EventDates.map((d) => (
                      <p>{`${d.date}: ${d.EventDateTimes.map(
                        (t) => `${t.time};`
                      )}`}</p>
                    ))}

                    <div className="d-flex justify-content-between bottom-card">
                      <div>
                        <Link
                          to={`/admin/schedule/event/${v.id}`}
                          className="btn btn-primary"
                        >
                          Lihat Event
                        </Link>
                      </div>

                      <div>
                        {/* <h3>Kapasitas</h3> */}

                        <p>
                          {/* <span
                            style={{
                              color: getColorPercent(
                                v.capacity * v.times.length -
                                  this.countQuota(v.times) || 0,
                                v.capacity * v.times.length
                              ),
                            }}
                          >{`${
                            v.capacity * v.times.length -
                            this.countQuota(v.times)
                          } `}</span>
                          /{` ${v.capacity * v.times.length}`} */}
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </InfiniteScroll>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.event,
  }
}

const mapDispatchToProps = {
  getEvents: event.getEvents,
  nextEvents: event.nextEvents,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEvent)
