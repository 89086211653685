import { axiosInstance } from "../../../helper"
import { sermon } from "../types"

export function getSermonCategories(params, config) {
  return {
    type: sermon.GET_CATEGORIES,
    payload: new axiosInstance.get("/sermon-category", {
      ...config,
      params,
    }),
  }
}

export default getSermonCategories
