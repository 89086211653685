import { Component } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap"
import Birthday from "../../../components/content/Birthday"
import Instagram from "../../../components/content/Instagram"
import SplashScreen from "../../../components/content/SplashScreen"
import { popupImage } from "../../../redux/actions"

class ImageSystem extends Component {
  constructor() {
    super()

    this.state = {
      igIsOpenCollapse: false,
      splashIsOpenCollapse: false,
      bdIsOpenCollapse: false,
    }
  }

  editPopupImage(id, data) {
    return this.props
      .editPopupImage(id, data)

      .then((res) => {
        toast.success("Edit Gambar Sukses")

        return res
      })
  }

  render() {
    return (
      <Container fluid className="mb-5">
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Image System</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Row>
          <Instagram />
          <SplashScreen editImage={this.editPopupImage.bind(this)} />
          <Birthday editImage={this.editPopupImage.bind(this)} />
        </Row>
      </Container>
    )
  }
}

const mapDispatchToProps = {
  editPopupImage: popupImage.updateItem,
}

export default connect(null, mapDispatchToProps)(ImageSystem)
