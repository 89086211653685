import { axiosInstance } from "../../../helper"
import { imageSystem } from "../types"

export function getBirthday(params, config) {
  return {
    type: imageSystem.GET_BIRTHDAY,
    payload: new axiosInstance.get(`/admin/popup-image/birthday`, {
      ...config,
      params,
    }),
  }
}

export default getBirthday
