import getEventBanner from "./getEventBanner"
import getInfoBanner from "./getInfoBanner"
import addEventBanner from "./addEventBanner"
import addInfoBanner from "./addInfoBanner"
import deleteEventBanner from "./deleteEventBanner"
import deleteInfoBanner from "./deleteInfoBanner"
import totalEventBanner from "./totalEventBanner"
import totalInfoBanner from "./totalInfoBanner"
import updateEventBanner from "./updateEventBanner"
import updateInfoBanner from "./updateInfoBanner"

export {
  getEventBanner,
  getInfoBanner,
  addInfoBanner,
  addEventBanner,
  deleteEventBanner,
  deleteInfoBanner,
  totalEventBanner,
  totalInfoBanner,
  updateEventBanner,
  updateInfoBanner,
}

const banner = {
  getEventBanner,
  getInfoBanner,
  addInfoBanner,
  addEventBanner,
  deleteInfoBanner,
  deleteEventBanner,
  totalEventBanner,
  totalInfoBanner,
  updateEventBanner,
  updateInfoBanner,
}

export default banner
