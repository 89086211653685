import { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
} from "reactstrap"
import Swal from "sweetalert2"
import { File } from "../../../components/Form"
import { leader } from "../../../redux/actions"

class CreateOurLeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: null,
      description: null,
      instagram: null,
      tiktok: null,
      facebook: null,
      showImage: null,
      image: null,

      readOnly: this.props.match.params.leaderId !== "create",
    }
  }

  handleDelete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteSermon(this.props.match.params.leaderId).then(() => {
          Swal.fire("Deleted!", "Sermon has been deleted.", "success")
          this.props.history.push(`/admin/content-management/our-leader`)
        })
      }
    })
  }

  getLeaderDetail() {
    this.props.getLeaderDetail(this.props.match.params.leaderId).then(() => {
      const detail = this.props.leader.detail
      this.setState({
        name: detail.name,
        description: detail.description,
        tiktok: detail.tiktok,
        facebook: detail.facebook,
        instagram: detail.instagram,
        isModalShow: false,
        showImage: detail.thumbnail_url,
      })
    })
  }

  proxySubmit(e) {
    e.preventDefault()

    const formData = new FormData()

    formData.append("name", this.state.name)
    formData.append("description", this.state.description)
    formData.append("tiktok", this.state.tiktok)
    formData.append("instagram", this.state.instagram)
    formData.append("facebook", this.state.facebook)
    if (this.state.image) {
      formData.append("image", this.state.image)
    }

    this.props
      .updateLeader(this.props.match.params.leaderId, formData)
      .then(() => {
        toast.success("Berhasil Update Leader")
        this.props.history.push("/admin/content-management/our-leader")
      })
  }
  componentDidMount() {
    if (this.state.readOnly) {
      this.getLeaderDetail()
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/content-management/our-leader">
                  Our Leader
                </Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Edit Leader</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <Row>
                <Col xl={8}>
                  <Form
                    onSubmit={this.proxySubmit.bind(this)}
                    id="create-our-leader"
                  >
                    <FormGroup>
                      <Label htmlFor="name">
                        Nama<span className="red-star">*</span>
                      </Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        id="name"
                        placeholder="Masukan Nama beserta gelar"
                        value={this.state.name}
                        disabled={this.state.readOnly}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="file">
                        Foto<span className="red-star">*</span>
                      </Label>
                      <File
                        onChange={(e) => {
                          this.setState({ image: e.target.files[0] })
                        }}
                        className="mb-2"
                        accept="image/png, image/gif, image/jpeg"
                        disabled={this.state.readOnly}
                      />

                      <span>
                        dimensi gambar 16:9, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="description">
                        Deskripsi<span className="red-star">*</span>
                      </Label>
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                        placeholder="Masukan Deskripsi"
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        value={this.state.description}
                        disabled={this.state.readOnly}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Facebook</Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          this.setState({ facebook: e.target.value })
                        }
                        id="name"
                        value={this.state.facebook}
                        placeholder="Masukan username facebook"
                        disabled={this.state.readOnly}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Instagram</Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          this.setState({ instagram: e.target.value })
                        }
                        id="name"
                        placeholder="Masukan username instagram"
                        value={this.state.instagram}
                        disabled={this.state.readOnly}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Tiktok</Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          this.setState({ tiktok: e.target.value })
                        }
                        id="name"
                        placeholder="Masukan username tiktok"
                        value={this.state.tiktok}
                        disabled={this.state.readOnly}
                      />
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    this.props.history.push(
                      "/admin/content-management/our-leader"
                    )
                  }}
                >
                  Batal
                </Button>
                {this.state.readOnly ? (
                  <>
                    {/* <Button
                      color="primary"
                      outline
                      onClick={this.handleDelete.bind(this)}
                    >
                      Hapus
                    </Button> */}
                    <div>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          this.setState({
                            readOnly: !this.state.readOnly,
                          })
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      type="submit"
                      form="create-our-leader"
                    >
                      Simpan
                    </Button>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    leader: state.leader,
  }
}

const mapDispatchToProps = {
  getLeaderDetail: leader.getLeaderDetail,
  updateLeader: leader.updateLeader,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateOurLeader)
