import { axiosInstance } from "../../../helper"
import { magazine } from "../types"

export function getDetailMagazine(id, params, config) {
  return {
    type: magazine.DETAIL_MAGAZINE,
    payload: new axiosInstance.get(`/admin/weekly-magazine/${id}`, {
      ...config,
      params,
    }),
  }
}

export default getDetailMagazine
