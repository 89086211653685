import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { banner, magazine } from "../../../redux/actions"

import { toast } from "react-toastify"
import { File } from "../../../components/Form"
import Swal from "sweetalert2"

class CreateWeeklyMagz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      image: null,
      file: null,
      type: null,

      title: null,
      index: null,

      isModalShow: false,
      showImage: null,

      readOnly: this.props.match.params.magzId !== "create",
    }
  }

  get data() {
    const formData = new FormData()

    if (this.state.image) {
      formData.append("thumbnail", this.state.image)
    }

    if (this.state.file) {
      formData.append("file", this.state.file)
    }

    formData.append("index", this.state.index)
    formData.append("title", this.state.title)

    return formData
  }

  goBack() {
    if (this.props.match.params.magzId === "create") {
      this.props.history.push("/admin/content-management/weekly-magz")
    }

    return this.setState({
      readOnly: !this.state.readOnly,
    })
  }

  addMagazineSubmit() {
    if (!this.state.image) {
      return toast.error("Gambar Belum diisi")
    }

    if (!this.state.file) {
      return toast.error("File Belum diisi")
    }

    this.props.addMagazine(this.data).then(() => {
      toast.success("Tambah Magazine Berhasil")
      this.props.history.push("/admin/content-management/weekly-magz")
    })
  }

  updateMagazine() {
    this.props
      .updateMagazine(this.props.match.params.magzId, this.data)
      .then(() => {
        toast.success("Update Magazine Berhasil")
        this.getWeeklyMagz()

        this.setState({
          readOnly: !this.state.readOnly,
        })
      })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (this.props.match.params.magzId !== "create") {
      return this.updateMagazine()
    }

    return this.addMagazineSubmit()
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  getTotalIndex() {
    this.props.totalMagazine()
  }

  getWeeklyMagz() {
    this.props.getMagazine(this.props.match.params.magzId).then(() => {
      let data = this.props.magazine

      this.setState({
        showImage: data.thumbnail_url,
        title: data.title,
        index: data.priority,
      })
    })
  }

  handleDelete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        return this.deleteMagazine(this.props.match.params.magzId)
      }
    })
  }

  deleteMagazine(id) {
    this.props.deleteMagazine(id).then(() => {
      toast.success("Berhasil Hapus Magazine")
      this.props.history.push("/admin/content-management/weekly-magz")
    })
  }

  componentDidMount() {
    this.getTotalIndex()

    if (this.state.readOnly) {
      this.getWeeklyMagz()
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/content-management/weekly-magz">
                  Majalah Mingguan
                </Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Tambah Majalah</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <Row>
                <Col xl={8}>
                  <Form
                    onSubmit={this.proxySubmit.bind(this)}
                    id="create-banner"
                  >
                    <FormGroup>
                      <Label htmlFor="title">Judul Majalah</Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        disabled={this.state.readOnly}
                        placeholder="Masukan Judul"
                        value={this.state.title}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="file">File Majalah</Label>
                      <File
                        onChange={(e) => {
                          this.setState({ file: e.target.files[0] })
                        }}
                        id="inputGroupFile01"
                        className="mb-2"
                        disabled={this.state.readOnly}
                      />

                      <span>ukuran gambar 5MB, format file .pdf</span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="image">Gambar Majalah</Label>

                      <Row>
                        <Col xl={10}>
                          <File
                            id="inputGroupFile02"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              this.setState({
                                image: e.target.files[0],
                                showImage: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              })
                            }}
                            disabled={this.state.readOnly}
                          />
                        </Col>

                        <Col xl={2} className="d-flex align-items-center">
                          <button
                            // color="link"
                            className="btn btn-link"
                            type="button"
                            onClick={this.modalToggle.bind(this)}
                            disabled={!this.state.showImage}
                          >
                            Tampilkan
                          </button>
                        </Col>
                      </Row>
                      <span>
                        dimensi gambar 9:16, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Urutan Majalah</Label>
                      <Row>
                        <Col xl={6}>
                          <Input
                            type="select"
                            onChange={(e) =>
                              this.setState({ index: e.target.value })
                            }
                            disabled={this.state.readOnly}
                            value={this.state.index}
                          >
                            <option value={0} selected disabled>
                              Pilih Index
                            </option>
                            {this.props.indexList.map((a, i, arr) => {
                              if (this.props.match.params.magzId !== "create") {
                                if (arr.length - 1 !== i) {
                                  return <option value={a}>{a}</option>
                                }
                              } else {
                                return <option value={a}>{a}</option>
                              }

                              return <></>
                            })}
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                {this.state.readOnly ? (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={this.handleDelete.bind(this)}
                    >
                      Hapus
                    </Button>
                    <div>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          this.setState({
                            readOnly: !this.state.readOnly,
                          })
                        }}
                      >
                        Edit Schedule
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={() => {
                        this.props.history.push(
                          "/admin/content-management/weekly-magz"
                        )
                      }}
                    >
                      Batal
                    </Button>
                    <Button color="primary" type="submit" form="create-banner">
                      Simpan
                    </Button>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader
              toggle={this.modalToggle.bind(this)}
              cssModule={{
                "modal-title": "h3",
              }}
            >
              Preview Image
            </ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}

const mapDispatchToProps = {
  addMagazine: magazine.addMagazine,
  getMagazine: magazine.getDetailMagazine,
  totalMagazine: magazine.totalMagazine,
  deleteMagazine: magazine.deleteMagazine,
  updateMagazine: magazine.updateMagazine,
}

const mapStateToProps = (state) => {
  return {
    indexList: state.magazine.index,
    magazine: state.magazine.detail,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWeeklyMagz)
