import { combineReducers } from "redux"
import authReducers from "./auth"
import churchReducer from "./church"
import worshipReducer from "./worship"
import worshipTimeReducer from "./worshipTime"
import userReducer from "./user"
import videoReducer from "./video"
import leaderReducer from "./leader"
import bannerReducer from "./banner"
import magazineReducer from "./magazine"
import eventReducer from "./event"
import accountReducer from "./account"
import churchRecordReducer from "./churchRecord"
import resourceReducer from "./resource"
import ministryReducer from "./ministry"
import sacramentBaptismReducer from "./baptism"
import sacramentMatrimonyReducer from "./matrimony"
import sacramentChildSubmissionReducer from "./childSubmission"
import instagramReducer from "./instagram"
import secretariatReducer from "./secretariat"
import roleReducer from "./role"
import dashboardReducer from "./dashboard"
import popupImageReducer from "./popupImage"
import sermonReducer from "./sermon"
import imageSystemReducer from "./imageSystem"

export default combineReducers({
  auth: authReducers,
  church: churchReducer,
  worship: worshipReducer,
  worshipTime: worshipTimeReducer,
  user: userReducer,
  video: videoReducer,
  leader: leaderReducer,
  banner: bannerReducer,
  magazine: magazineReducer,
  event: eventReducer,
  account: accountReducer,
  churchRecord: churchRecordReducer,
  resource: resourceReducer,
  ministry: ministryReducer,
  baptism: sacramentBaptismReducer,
  matrimony: sacramentMatrimonyReducer,
  childSubmission: sacramentChildSubmissionReducer,
  instagram: instagramReducer,
  secretariat: secretariatReducer,
  role: roleReducer,
  dashboard: dashboardReducer,
  popupImage: popupImageReducer,
  sermon: sermonReducer,
  imageSystem: imageSystemReducer,
})
