export const banner = {
  GET_EVENT_BANNER: "banner/event/get",
  GET_BANNER: "banner/info/get",
  TOTAL_BANNER: "banner/info/total",
  TOTAL_EVENT_BANNER: "banner/event/total",
  ADD_EVENT_BANNER: "banner/event/add",
  ADD_INFO_BANNER: "banner/info/add",
  DELETE_EVENT_BANNER: "banner/event/delete",
  DELETE_INFO_BANNER: "banner/info/delete",
  UPDATE_INFO_BANNER: "banner/info/update",
  UPDATE_EVENT_BANNER: "banner/event/update",
}

export default banner
