import { promiseState } from "../../helper"
import { banner } from "../../redux/actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
  isLoading: false,
  errors: [],
  message: "",
  eventData: [],
  infoData: [],
  eventIndex: [],
  infoIndex: [],
}

function bannerReducer(state = initialState, action) {
  switch (action.type) {
    case pendingState(banner.GET_BANNER): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(banner.GET_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(banner.GET_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
        infoData: action.payload.data.data,
      })
    }

    case pendingState(banner.TOTAL_BANNER): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(banner.TOTAL_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(banner.TOTAL_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
        infoIndex: Array.from(
          {
            length:
              action.payload.data.data === 10
                ? 10
                : action.payload.data.data + 1,
          },
          (_, i) => i + 1
        ),
      })
    }

    case pendingState(banner.TOTAL_EVENT_BANNER): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(banner.TOTAL_EVENT_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(banner.TOTAL_EVENT_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
        eventIndex: Array.from(
          {
            length:
              action.payload.data.data === 10
                ? 10
                : action.payload.data.data + 1,
          },
          (_, i) => i + 1
        ),
      })
    }

    case pendingState(banner.GET_EVENT_BANNER): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(banner.GET_EVENT_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(banner.GET_EVENT_BANNER): {
      return Object.assign({}, state, {
        isLoading: false,
        eventData: action.payload.data.data,
      })
    }

    default:
      return state
  }
}

export default bannerReducer
