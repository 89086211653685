import { Component } from "react"
import {
  Container,
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Button,
  Row,
  Col,
  CardHeader,
} from "reactstrap"

import { instagram } from "../../redux/actions"

import { connect } from "react-redux"
import { toast } from "react-toastify"
import { File } from "../Form"

class Instagram extends Component {
  constructor() {
    super()
    this.state = {
      isModalShow: false,
      image: null,
      igIsOpenCollapse: false,
    }
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  changeIgProfile(e) {
    e.preventDefault()
    const formData = new FormData()

    formData.append("image", this.state.image)

    this.props.changeIgProfile(formData).then(() => {
      this.getIgProfile()
      toast.success("Berhasil Update Profile")
      this.modalToggle()
    })
  }

  getIgProfile() {
    this.props.getIgProfile()
  }

  componentDidMount() {
    this.getIgProfile()
  }

  render() {
    return (
      <Col xl={12} className="mb-3">
        <Card>
          <CardHeader
            onClick={() => {
              this.setState({
                igIsOpenCollapse: !this.state.igIsOpenCollapse,
              })
            }}
            className={`border-0 toggler-collapse ${
              this.state.igIsOpenCollapse ? "glow-collapse-active" : ""
            }`}
          >
            Foto Profil Instagram
          </CardHeader>

          <Collapse isOpen={this.state.igIsOpenCollapse}>
            <CardBody>
              <Row className="d-flex justify-content-center">
                <div className="d-flex justify-content-center col-12 pb-3">
                  <img
                    src={this.props.instagram.data}
                    alt="instagram"
                    style={{
                      maxWidth: "300px",
                    }}
                  />
                </div>

                <Button color="primary" onClick={this.modalToggle.bind(this)}>
                  Ganti Photo
                </Button>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
        <Modal
          toggle={this.modalToggle.bind(this)}
          isOpen={this.state.isModalShow}
        >
          <ModalHeader
            toggle={this.modalToggle.bind(this)}
            cssModule={{
              "modal-title": "h3",
            }}
          >
            Change Profile Picture
          </ModalHeader>
          <ModalBody>
            <Container>
              <Form
                onSubmit={this.changeIgProfile.bind(this)}
                id="change-ig-profile"
              >
                <FormGroup>
                  <Label htmlFor="image">Profile Picture </Label>

                  <File
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      this.setState({ image: e.target.files[0] })
                    }}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    outline
                    onClick={this.modalToggle.bind(this)}
                    type="button"
                  >
                    Batal
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    form="change-ig-profile"
                  >
                    Simpan
                  </Button>
                </div>
              </Form>
            </Container>
          </ModalBody>
        </Modal>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    instagram: state.instagram,
  }
}

const mapDispatchToProps = {
  getIgProfile: instagram.getIgProfile,
  changeIgProfile: instagram.changeIgProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(Instagram)
