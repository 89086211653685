import { axiosInstance } from "../../../helper"
import { popupImage } from "../types"

export function updateItem(id, data, config) {
  return {
    type: popupImage.UPDATE,
    payload: new axiosInstance.put(`/admin/popup-image/${id}`, data, config),
  }
}

export default updateItem
