import { axiosInstance } from "../../../helper"
import { sermon } from "../types"

export function getSermonDetail(id, params, config) {
  return {
    type: sermon.GET_DETAIL,
    payload: new axiosInstance.get(`/admin/sermon/${id}`, {
      ...config,
      params,
    }),
  }
}

export default getSermonDetail
