import { Component } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import Swal from "sweetalert2"
import { File } from "../../../components/Form"
import { popupImage } from "../../../redux/actions"
class PopupImage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalShow: false,
      image: null,
      modalType: 1,
      selectedId: null,
    }
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
      image: null,
    })
  }

  getPopupImage() {
    this.props.getPopupImage({
      types: ["promotion"],
    })
  }

  addPopupImage() {
    const form = new FormData()
    if (this.state.image) {
      form.append("image", this.state.image)
    }

    this.props
      .addPopupImage(form, {
        params: {
          type: "promotion",
        },
      })
      .then(() => {
        toast.success("Tambah Gambar Sukses")

        this.setState(
          {
            selectedId: null,
            image: null,
            isModalShow: false,
          },
          () => {
            this.getPopupImage()
          }
        )
      })
  }

  editPopupImage() {
    const form = new FormData()
    if (this.state.image) {
      form.append("image", this.state.image)
    }

    this.props
      .editPopupImage(this.state.selectedId, form, {
        params: "promotion",
      })

      .then(() => {
        toast.success("Edit Gambar Sukses")

        this.setState(
          {
            selectedId: null,
            image: null,
            isModalShow: false,
          },
          () => {
            this.getPopupImage()
          }
        )
      })
  }

  handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deletePopupImage(id).then(() => {
          Swal.fire("Deleted!", "Image has been deleted.", "success")
          this.getPopupImage()
        })
      }
    })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (this.state.modalType === 1) {
      this.addPopupImage()
    } else {
      this.editPopupImage()
    }
  }

  componentDidMount() {
    this.getPopupImage()
  }

  render() {
    const totalData = this.props.popupImage.data.length
    const noImageTotal = 3 - totalData

    return (
      <Container fluid className="mb-5">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>Gambar Pop-up</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Row className="mb-4 mt-3">
          {this.props.popupImage.data.map((popupImage) => {
            return (
              <Col xl={3}>
                <Card className="mb-3 banner">
                  <CardBody>
                    <img src={popupImage.file_url} alt={"test"} width="100%" />
                    <div className="d-flex flex-column">
                      <div className="popup-btm">
                        <Button
                          color="primary"
                          type="button"
                          block
                          onClick={() => {
                            this.setState({
                              selectedId: popupImage.id,
                              isModalShow: true,
                              modalType: 2,
                              image: null,
                            })
                          }}
                        >
                          Ganti Gambar
                        </Button>
                      </div>

                      <div className="popup-btm">
                        <button
                          // color="link"
                          className="btn btn-link btn-block"
                          type="button"
                          onClick={() => {
                            this.handleDelete(popupImage.id)
                          }}
                          // disabled={!this.state.showImage}
                        >
                          Hapus Gambar
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
          })}

          {noImageTotal >= 1 ? (
            <Col xl={3}>
              <Card className="mb-3 banner">
                <CardBody>
                  <img
                    src={require("../../../assets/img/no-image.png").default}
                    alt={"test"}
                    width="100%"
                  />
                  <div className="d-flex flex-column">
                    <div className="popup-btm">
                      <Button
                        color="primary"
                        type="button"
                        block
                        onClick={() => {
                          this.setState({
                            isModalShow: true,
                            modalType: 1,
                            selectedId: null,
                            image: null,
                          })
                        }}
                      >
                        Tambah Gambar
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : null}
        </Row>
        <Modal
          toggle={this.modalToggle.bind(this)}
          isOpen={this.state.isModalShow}
        >
          <ModalHeader
            toggle={this.modalToggle.bind(this)}
            cssModule={{
              "modal-title": "h3",
            }}
          >
            {this.state.modalType === 1 ? "Tambah Gambar" : "Edit Gambar"}
          </ModalHeader>
          <ModalBody>
            <Container>
              <Form
                id="popup-image-form"
                onSubmit={this.proxySubmit.bind(this)}
              >
                <FormGroup>
                  <Label htmlFor="image">Gambar </Label>

                  <File
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      this.setState({ image: e.target.files[0] })
                    }}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    outline
                    onClick={this.modalToggle.bind(this)}
                    type="button"
                  >
                    Batal
                  </Button>
                  <Button color="primary" type="submit" form="popup-image-form">
                    Simpan
                  </Button>
                </div>
              </Form>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    popupImage: state.popupImage,
  }
}

const mapDispatchToProps = {
  getPopupImage: popupImage.getPopupImage,
  addPopupImage: popupImage.addItem,
  editPopupImage: popupImage.updateItem,
  deletePopupImage: popupImage.deleteItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupImage)
