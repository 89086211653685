import getLastMagazine from "./getLastMagazine"
import changeMagazine from "./changeMagazine"
import addMagazine from "./addMagazine"
import deleteMagazine from "./deleteMagazine"
import getMagazine from "./getMagazine"
import totalMagazine from "./totalMagazine"
import getDetailMagazine from "./getDetailMagazine"
import updateMagazine from "./updateMagazine"

const magazine = {
  getLastMagazine,
  changeMagazine,
  addMagazine,
  deleteMagazine,
  getMagazine,
  totalMagazine,
  getDetailMagazine,
  updateMagazine,
}

export {
  getLastMagazine,
  changeMagazine,
  addMagazine,
  deleteMagazine,
  getMagazine,
  totalMagazine,
  getDetailMagazine,
  updateMagazine,
}

export default magazine
