import getSplashScreen from "./getSplashScreen"
import getBirthday from "./getBirthday"

export { getSplashScreen, getBirthday }

const imageSystem = {
  getSplashScreen,
  getBirthday,
}

export default imageSystem
