import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"
import CreatableSelect from "react-select/creatable"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { connect } from "react-redux"
import { secretariat } from "../../../redux/actions"
import Swal from "sweetalert2"
import { File } from "../../../components/Form"

class CreateSecretariat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      secretariat_id: null,
      secretariatValue: null,
      position: "",
      description: "",
      image: null,
      isModalShow: false,
      showImage: null,

      readOnly: this.props.match.params.secretariatId !== "create",

      editMode: false,
    }
  }

  handleDelete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props
          .deleteItem(this.props.match.params.secretariatId)
          .then(() => {
            Swal.fire("Deleted!", "Secretariat has been deleted.", "success")
            this.props.history.push(`/admin/secretariat`)
          })
      }
    })
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  goBack() {
    if (this.props.match.params.secretariatId === "create") {
      this.props.history.push("/admin/secretariat")
    }

    return this.setState({
      readOnly: !this.state.readOnly,
    })
  }

  addItem() {
    const formData = new FormData()

    formData.append("name", this.state.name)
    formData.append("position", this.state.position)
    formData.append("description", this.state.description)
    formData.append("secretariat_id", this.state.secretariatValue.value)
    formData.append("image", this.state.image[0])

    this.props
      .addItem(formData)
      .then(() => {
        toast.success("Berhasil Tambah Sekretariat")
        this.props.history.push("/admin/secretariat")
      })
      .catch((error) => {
        toast.error(
          error.response ? error.response.data.message : error.message
        )
      })
  }

  getItemDetail() {
    this.props.getItemDetail(this.props.match.params.secretariatId).then(() => {
      const detail = this.props.secretariat.itemDetail
      this.setState({
        name: detail.name,
        secretariat_id: detail.secretariat_id,
        secretariatValue: {
          label: detail.Secretariat.name,
          value: detail.Secretariat.id,
        },
        description: detail.description,
        position: detail.position,
        isModalShow: false,
        showImage: detail.image,
      })
    })
  }

  getSecretariat() {
    this.props.getSecretariat()
  }

  componentDidMount() {
    this.getSecretariat()

    if (this.state.readOnly) {
      this.getItemDetail()
    }
  }

  get secretariats() {
    return this.props.secretariat.data.map((secretariat) => {
      return {
        label: secretariat.name,
        value: secretariat.id,
        data: secretariat,
      }
    })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (this.props.match.params.secretariatId === "create") {
      return this.addItem()
    }

    return this.updateItem()
  }

  updateItem() {
    const formData = new FormData()

    formData.append("name", this.state.name)
    formData.append("position", this.state.position)
    formData.append("description", this.state.description)
    formData.append("secretariat_id", this.state.secretariatValue.value)
    if (this.state.image) {
      formData.append("image", this.state.image[0])
    }

    this.props
      .updateItem(this.props.match.params.secretariatId, formData)
      .then(() => {
        toast.success("Berhasil Update Sekretariat")
        this.setState({
          readOnly: true,
        })

        this.getItemDetail()
      })
      .catch((error) => {
        toast.error(
          error.response ? error.response.data.message : error.message
        )
      })
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/secretariat">Secretariat</Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Tambah Secretariat</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <h1 className="mb-4">Detail Secretariat</h1>
              <Row>
                <Col xl={8}>
                  <Form
                    onSubmit={this.proxySubmit.bind(this)}
                    id="create-event"
                  >
                    <FormGroup>
                      <Label htmlFor="name">Nama Lengkap</Label>
                      <Input
                        readOnly={this.state.readOnly}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Masukan Nama Lengkap"
                        onChange={(e) => {
                          this.setState({
                            name: e.target.value,
                          })
                        }}
                        value={this.state.name}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="exampleSelect">Tipe Secretariat</Label>
                      <CreatableSelect
                        isClearable
                        isDisabled={this.state.readOnly}
                        onChange={(e) => {
                          if (e && e["__isNew__"]) {
                            this.setState({
                              secretariatValue: e,
                              secretariat_id: null,
                            })
                          } else {
                            this.setState({
                              secretariatValue: e,
                              secretariat_id: e ? e.value : null,
                            })
                          }
                        }}
                        options={this.secretariats}
                        placeholder="Pilih"
                        value={this.state.secretariatValue}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="position">Jabatan</Label>
                      <Input
                        readOnly={this.state.readOnly}
                        type="text"
                        name="position"
                        id="position"
                        placeholder="Masukan Jabatan"
                        onChange={(e) => {
                          this.setState({
                            position: e.target.value,
                          })
                        }}
                        value={this.state.position}
                      />
                    </FormGroup>

                    <Row>
                      <Col xl={10}>
                        <FormGroup>
                          <Label htmlFor="description">Biodata Singkat</Label>
                          <Input
                            type="textarea"
                            name="description"
                            id="description"
                            placeholder="Masukan Biodata Singkat"
                            onChange={(e) => {
                              this.setState({
                                description: e.target.value,
                              })
                            }}
                            value={this.state.description}
                            readOnly={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label htmlFor="image">Gambar</Label>
                      <Row>
                        <Col xl={10}>
                          <File
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              this.setState({
                                image: e.target.files,
                                showImage: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              })
                            }}
                            disabled={this.state.readOnly}
                            className="mb-2"
                          />
                        </Col>

                        <Col xl={2}>
                          <Button
                            color="primary"
                            type="button"
                            onClick={this.modalToggle.bind(this)}
                            disabled={!this.state.showImage}
                          >
                            Tampilkan
                          </Button>
                        </Col>
                      </Row>

                      <span className="mt-2">
                        dimensi gambar 16:9, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Container className="d-flex">
                <div className="d-flex flex-row align-items-center mb-3 ml-auto">
                  {this.state.readOnly ? (
                    <>
                      <Button
                        color="primary"
                        outline
                        onClick={this.handleDelete.bind(this)}
                      >
                        Hapus
                      </Button>
                      <div>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            this.setState({
                              readOnly: !this.state.readOnly,
                            })
                          }}
                        >
                          Edit Sekretariat
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        outline
                        onClick={this.goBack.bind(this)}
                      >
                        Batal
                      </Button>
                      <Button color="primary" type="submit" form="create-event">
                        Simpan
                      </Button>
                    </>
                  )}
                </div>
              </Container>
            </CardBody>
          </Card>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    secretariat: state.secretariat,
  }
}

const mapDispatchToProps = {
  getSecretariat: secretariat.getSecretariat,
  getItemDetail: secretariat.getItemDetail,
  addItem: secretariat.addItem,
  updateItem: secretariat.updateItem,
  deleteItem: secretariat.deleteItem,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateSecretariat)
