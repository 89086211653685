import { promiseState } from "../../helper"
import { imageSystem } from "../../redux/actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
  isLoading: false,
  errors: [],
  hasMore: false,
  message: "",
  birthday: {},
  splash: {},
}

function imageSystemReducer(state = initialState, action) {
  switch (action.type) {
    case pendingState(imageSystem.GET_SPLASH): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(imageSystem.GET_SPLASH): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(imageSystem.GET_SPLASH): {
      return Object.assign({}, state, {
        isLoading: false,
        splash: action.payload.data.data,
      })
    }

    case pendingState(imageSystem.GET_BIRTHDAY): {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }

    case rejectedState(imageSystem.GET_BIRTHDAY): {
      return Object.assign({}, state, {
        isLoading: false,
      })
    }

    case fulfilledState(imageSystem.GET_BIRTHDAY): {
      return Object.assign({}, state, {
        isLoading: false,
        birthday: action.payload.data.data,
      })
    }

    default:
      return state
  }
}

export default imageSystemReducer
