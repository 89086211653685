import { axiosInstance } from "../../../helper"
import { popupImage } from "../types"

export function addItem(body, config) {
  return {
    type: popupImage.ADD,
    payload: new axiosInstance.post(`/admin/popup-image/`, body, config),
  }
}

export default addItem
