import { axiosInstance } from "../../../helper"
import { event } from "../types"

export function getEventNe(params, config) {
  return {
    type: event.GET_EVENTS,
    payload: new axiosInstance.get("/event", {
      ...config,
      params,
    }),
  }
}

export default getEventNe
