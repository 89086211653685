import { Component } from "react"
import { connect } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import { auth } from "../../redux/actions"
class ProtectedRoute extends Component {
  render() {
    if (!this.props.auth?.data?.token) {
      this.props.logout()
      return <Redirect to="/auth/login" />
    }

    return <Route {...this.props} />
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth }
}

const mapDispatchToProps = {
  logout: auth.logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
