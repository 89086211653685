import { axiosInstance } from "../../../helper"
import { leader } from "../types"

export function updateLeader(id, data, config) {
  return {
    type: leader.UPDATE_LEADER,
    payload: new axiosInstance.put(`/admin/leader/${id}`, data, config),
  }
}

export default updateLeader
