import addItem from "./addItem"
import deleteItem from "./deleteItem"
import getPopupImage from "./getPopupimage"
import updateItem from "./updateItem"

export { addItem, deleteItem, updateItem, getPopupImage }

const popupImage = {
  addItem,
  deleteItem,
  updateItem,
  getPopupImage,
}

export default popupImage
