import { axiosInstance } from "../../../helper"
import { leader } from "../types"

export function getLeaderDetail(id, params, config) {
  return {
    type: leader.GET_DETAIL,
    payload: new axiosInstance.get(`/admin/leader/${id}`, {
      ...config,
      params,
    }),
  }
}

export default getLeaderDetail
