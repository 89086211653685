import addItem from "./addItem"
import deleteItem from "./deleteItem"
import getSermon from "./getSermon"
import getSermonDetail from "./getSermonDetail"
import updateItem from "./updateItem"
import getCategories from "./getCategories"

export {
  addItem,
  deleteItem,
  updateItem,
  getSermon,
  getSermonDetail,
  getCategories,
}

const popupImage = {
  addItem,
  deleteItem,
  updateItem,
  getSermon,
  getSermonDetail,
  getCategories,
}

export default popupImage
