import { axiosInstance } from "../../../helper"
import { magazine } from "../types"

export function addMagazine(body, config) {
  return {
    type: magazine.ADD_MAGAZINE,
    payload: new axiosInstance.post(`/admin/weekly-magazine`, body, config),
  }
}

export default addMagazine
