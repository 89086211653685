import { axiosInstance } from "../../../helper"
import { popupImage } from "../types"

export function deleteItem(id, config) {
  return {
    type: popupImage.DELETE,
    payload: new axiosInstance.delete(`/admin/popup-image/${id}`, config),
  }
}

export default deleteItem
