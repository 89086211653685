export const sermon = {
  ADD: "sermon/add",
  GET: "sermon/get",
  GET_DETAIL: "sermon/get/detail",
  GET_CATEGORIES: "sermon/get/categories",
  EDIT: "sermon/edit",
  DELETE: "sermon/delete",
}

export default sermon
