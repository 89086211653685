import { axiosInstance } from "../../../helper"
import { sermon } from "../types"

export function getSermon(params, config) {
  return {
    type: sermon.GET,
    payload: new axiosInstance.get("/admin/sermon", {
      ...config,
      params,
    }),
  }
}

export default getSermon
