import { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  Breadcrumb,
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap"
import { leader } from "../../../redux/actions"

class OurLeader extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.props.getLeader()
  }
  render() {
    return (
      <Container fluid className="mb-5">
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Our Leader & Wakil Gembala</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Row>
          {this.props.leader.data.map((leader, index) => (
            <Col xl={6}>
              <Card className="mb-3">
                <CardBody>
                  <h2>{index === 0 ? "Our Leader" : "Wakil Gembala"}</h2>
                  <p>{leader.subtitle}</p>
                  <div className="img-leader mb-3">
                    <img
                      src={leader.image}
                      alt=""
                      width={"100%"}
                      style={{
                        borderRadius: "7px",
                      }}
                    />
                  </div>

                  <h1>{leader.name}</h1>
                  <p>{leader.description}</p>
                </CardBody>

                <CardFooter className="d-flex border-0">
                  <Link
                    to={`/admin/content-management/our-leader/${leader.id}`}
                    className="btn btn-primary ml-auto"
                  >
                    Edit Konten
                  </Link>
                </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    leader: state.leader,
  }
}
const mapDispatchToProps = {
  getLeader: leader.getLeader,
}

export default connect(mapStateToProps, mapDispatchToProps)(OurLeader)
