import { Component } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardHeader,
  Button,
  Label,
  Input,
} from "reactstrap"
// import Slider from "react-slick"

import React from "react"
import { Line, Bar } from "react-chartjs-2"
// import {
//   WeeklyMagazine,
//   StreamingResource,
//   SpecialGuest,
//   Instagram,
// } from "../components/content"
import { connect } from "react-redux"
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
import { dashboard, church, event } from "../redux/actions"
import moment from "moment"
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}
class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeNav: 2,
      activeSchedule: 2,
      churchValue: null,
      date: null,
      church_id: null,
      event_id: null,
    }
  }

  get churchOptions() {
    return this.props.church.data.map((v) => {
      return {
        label: v.name,
        value: v.id,
        data: v,
      }
    })
  }

  get eventOptions() {
    return this.props.event.data.map((v) => {
      return {
        label: v.name,
        value: v.id,
        data: v,
      }
    })
  }

  get userChartParams() {
    return {
      filter: this.state.activeNav === 1 ? "year" : "month",
    }
  }

  get scheduleChartParams() {
    let date =
      this.state.activeSchedule === 1
        ? this.state.date
        : this.eventOptions.find(
            (v) => String(v.value) === String(this.state.event_id)
          )?.data?.event_date_schedule_id
    const query = {
      type: this.state.activeSchedule,
      date,
    }

    if (this.state.activeSchedule === 1) {
      query.church_id = this.state.church_id
    } else {
      query.event_id = this.state.event_id
    }
    return query
  }

  getCard() {
    this.props.getCard()
  }

  getUserChart() {
    this.props.getUserChart(this.userChartParams)
  }

  getScheduleChart() {
    this.props.getScheduleChart(this.scheduleChartParams)
  }

  getEvents() {
    this.props.getEvents({ limit: 999999 }).then(() => {
      if (this.props.event.data.length) {
        this.setState({ event_id: this.props.event.data[0].id }, () => {
          this.getScheduleChart()
        })
      }
    })
  }

  getChurches() {
    this.props.getChurches({
      limit: Infinity,
    })
  }

  componentDidMount() {
    this.getCard()
    this.getUserChart()

    this.getChurches()
    this.getEvents()
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div className="header">
          <Container fluid>
            {/* <h1>Konten Managemen</h1>
            <Slider
              dots
              speed={500}
              slidesToShow={3}
              slidesToScroll={3}
              className="mb-3"
            >
              <Instagram />
              <WeeklyMagazine />
              <StreamingResource />

              <SpecialGuest />
            </Slider> */}
            <h1>Informasi Schedule / Event</h1>
            <p>
              {`Minggu lalu (${this.props.dashboard.card.lastWeek.startDate} - ${this.props.dashboard.card.lastWeek.endDate})`}
            </p>
            <div className="header-body mb-3">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Kehadiran Ibadah
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {`${this.props.dashboard.card.lastWeek.worship.presentTotal}`}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <i className="fas fa-check"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Jadwal Ibadah
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.dashboard.card.lastWeek.worship.total}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <svg
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 1.5H8.865C8.55 0.63 7.725 0 6.75 0C5.775 0 4.95 0.63 4.635 1.5H1.5C0.675 1.5 0 2.175 0 3V13.5C0 14.325 0.675 15 1.5 15H12C12.825 15 13.5 14.325 13.5 13.5V3C13.5 2.175 12.825 1.5 12 1.5ZM7.5 12.75H6V11.25H7.5V12.75ZM7.5 9.75H6V5.25H7.5V9.75ZM6.75 3C6.3375 3 6 2.6625 6 2.25C6 1.8375 6.3375 1.5 6.75 1.5C7.1625 1.5 7.5 1.8375 7.5 2.25C7.5 2.6625 7.1625 3 6.75 3Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Kehadiran Acara
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {`${this.props.dashboard.card.lastWeek.event.presentTotal}`}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <i className="fas fa-times"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Acara
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.dashboard.card.lastWeek.event.total}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <i className="fas fa-times"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            <p>
              {`Minggu Ini (${this.props.dashboard.card.thisWeek.startDate} - ${this.props.dashboard.card.thisWeek.endDate})`}
            </p>
            <div className="header-body mb-3">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Akan Hadir Ibadah
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {
                              this.props.dashboard.card.thisWeek.worship
                                .bookedTotal
                            }
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <i className="fas fa-check"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Jadwal Ibadah
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.dashboard.card.thisWeek.worship.total}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <svg
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 1.5H8.865C8.55 0.63 7.725 0 6.75 0C5.775 0 4.95 0.63 4.635 1.5H1.5C0.675 1.5 0 2.175 0 3V13.5C0 14.325 0.675 15 1.5 15H12C12.825 15 13.5 14.325 13.5 13.5V3C13.5 2.175 12.825 1.5 12 1.5ZM7.5 12.75H6V11.25H7.5V12.75ZM7.5 9.75H6V5.25H7.5V9.75ZM6.75 3C6.3375 3 6 2.6625 6 2.25C6 1.8375 6.3375 1.5 6.75 1.5C7.1625 1.5 7.5 1.8375 7.5 2.25C7.5 2.6625 7.1625 3 6.75 3Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Akan Hadir Acara
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {
                              this.props.dashboard.card.thisWeek.event
                                .bookedTotal
                            }
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <i className="fas fa-times"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="6" className="mb-2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Acara
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.dashboard.card.thisWeek.event.total}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape glow-bg-primary text-white shadow">
                            <i className="fas fa-times"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid className="mb-5">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase ls-1 mb-1">Overview</h6>
                      <h2 className="mb-0">Growth User {moment().year()}</h2>
                    </div>
                    <div className="col">
                      <div className="justify-content-end nav nav-pills">
                        <Button
                          type="button"
                          color={
                            this.state.activeNav === 1 ? "primary" : "secondary"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                activeNav: 1,
                              },
                              () => {
                                this.getUserChart()
                              }
                            )
                          }}
                        >
                          <span className="d-none d-md-block">Year</span>
                          <span className="d-md-none">Y</span>
                        </Button>

                        <Button
                          type="button"
                          color={
                            this.state.activeNav === 2 ? "primary" : "secondary"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                activeNav: 2,
                              },
                              () => {
                                this.getUserChart()
                              }
                            )
                          }}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </Button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      options={options}
                      data={this.props.dashboard.userChart}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid className="mb-5">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase ls-1 mb-1">Overview</h6>
                      <h2 className="mb-0">Schedule Chart</h2>
                    </div>
                  </Row>
                </CardHeader>

                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <div className="justify-content-start nav nav-pills">
                        <Button
                          type="button"
                          color={
                            this.state.activeSchedule === 2
                              ? "primary"
                              : "secondary"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                activeSchedule: 2,
                              },
                              () => {
                                this.getScheduleChart()
                              }
                            )
                          }}
                        >
                          <span className="d-none d-md-block">Event</span>
                          <span className="d-md-none">E</span>
                        </Button>

                        <Button
                          type="button"
                          color={
                            this.state.activeSchedule === 1
                              ? "primary"
                              : "secondary"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                activeSchedule: 1,
                              },
                              () => {
                                this.getScheduleChart()
                              }
                            )
                          }}
                        >
                          <span className="d-none d-md-block">Ibadah</span>
                          <span className="d-md-none">I</span>
                        </Button>
                      </div>
                    </div>
                    <div className="col">
                      <div className="justify-content-end">
                        <div className="row">
                          {this.state.activeSchedule === 1 ? (
                            <>
                              <div className="col">
                                <Label htmlFor="dateFilter">
                                  Filter Tanggal
                                </Label>
                                <Input
                                  id="dateFilter"
                                  name="dateFilter"
                                  type="date"
                                  value={this.state.date}
                                  onChange={(e) => {
                                    this.setState(
                                      { date: e.target.value },
                                      () => {
                                        this.getScheduleChart()
                                      }
                                    )
                                  }}
                                />
                              </div>

                              <div className="col">
                                <Label htmlFor="church">Gereja</Label>
                                <Input
                                  id="church"
                                  name="church"
                                  type="select"
                                  value={this.state.church_id}
                                  onChange={(e) => {
                                    this.setState(
                                      { church_id: e.target.value },
                                      () => {
                                        this.getScheduleChart()
                                      }
                                    )
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Pilih Cabang
                                  </option>
                                  {this.churchOptions.map((c) => {
                                    return (
                                      <option value={c.value}>{c.label}</option>
                                    )
                                  })}
                                </Input>
                              </div>
                            </>
                          ) : (
                            <div className="col">
                              <Label htmlFor="event">Event</Label>
                              <Input
                                id="event"
                                name="event"
                                type="select"
                                value={this.state.event_id}
                                onChange={(e) => {
                                  this.setState(
                                    { event_id: e.target.value },
                                    () => {
                                      this.getScheduleChart()
                                    }
                                  )
                                }}
                              >
                                <option value="" disabled selected>
                                  Pilih Event
                                </option>
                                {this.eventOptions.map((c) => {
                                  return (
                                    <option value={c.value}>{c.label}</option>
                                  )
                                })}
                              </Input>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Bar
                      options={options}
                      data={this.props.dashboard.scheduleChart}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard,
    church: state.church,
    event: state.event,
  }
}

const mapDispatchToProps = {
  getCard: dashboard.getCard,
  getUserChart: dashboard.getUserChart,
  getScheduleChart: dashboard.getScheduleChart,
  getChurches: church.getChurches,
  getEvents: event.getEvents,
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
