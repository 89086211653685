import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  CardHeader,
  Row,
  Card,
  CardImg,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
// core components
import { Component } from "react"

import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"
import { connect } from "react-redux"
import { ministry } from "../../../redux/actions"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { File } from "../../../components/Form"

class Ministry extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      image: "",
      type: 1,

      selectedId: null,
    }
  }
  getMinistries() {
    this.props.getMinistries()
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
      name: "",
      image: "",
      type: 1,
    })
  }

  componentDidMount() {
    this.getMinistries()
  }

  handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteMinistry(id).then(() => {
          Swal.fire("Deleted!", "Ministry has been deleted.", "success")
          this.getMinistries()
        })
      }
    })
  }

  addMinistry() {
    const formData = new FormData()

    formData.append("name", this.state.name)
    formData.append("image", this.state.image[0])

    this.props.addMinistry(formData).then(() => {
      toast.success("Berhasil Tambah Ministry")
      this.modalToggle()
      this.getMinistries()
    })
  }

  updateMinistry() {
    const formData = new FormData()

    formData.append("name", this.state.name)
    if (this.state.image) {
      formData.append("image", this.state.image[0])
    }

    this.props.updateMinistry(this.state.selectedId, formData).then(() => {
      toast.success("Berhasil Update Ministry")
      this.modalToggle()
      this.getMinistries()
    })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (this.state.type === 1) {
      this.addMinistry()
    } else {
      this.updateMinistry()
    }
  }

  render() {
    return (
      <Container fluid className="mb-5">
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Ministry</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="d-flex justify-content-end mb-3">
          {/* Page content */}
          <Button
            color="primary"
            onClick={() => {
              this.setState({
                type: 1,
                isModalShow: true,
              })
            }}
          >
            Tambah Ministry
          </Button>
        </div>

        <Row>
          {this.props.ministry.data.map((ministry) => (
            <Col xl={`2c4`}>
              <Card className="mb-3">
                <CardImg top src={ministry.image} />
                <CardHeader>
                  <h4>{ministry.name}</h4>
                </CardHeader>

                <CardBody className="d-flex">
                  <Link to={`/admin/ministry/${ministry.id}/content`}>
                    Update
                  </Link>
                  <div className="ml-auto d-flex flex-row align-items-center">
                    <button
                      className="btn-link mr-3"
                      onClick={(e) => {
                        this.setState({
                          selectedId: ministry.id,
                          type: 2,
                          isModalShow: true,

                          name: ministry.name,
                        })
                      }}
                    >
                      <Icon icon="clarity:edit-solid" />
                    </button>

                    <button
                      className="btn-link"
                      onClick={(e) => {
                        this.handleDelete(ministry.id)
                      }}
                    >
                      <Icon icon="mdi:delete" />
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        <Modal
          toggle={this.modalToggle.bind(this)}
          isOpen={this.state.isModalShow}
        >
          <ModalHeader
            toggle={this.modalToggle.bind(this)}
            cssModule={{
              "modal-title": "h3",
            }}
          >
            {this.state.type === 1 ? "Tambah Ministry" : "Update Ministry"}
          </ModalHeader>
          <ModalBody>
            <Container>
              <Form onSubmit={this.proxySubmit.bind(this)} id="ministry-form">
                <FormGroup>
                  <Label htmlFor="name">Nama</Label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Masukan Nama"
                    onChange={(e) => {
                      this.setState({
                        name: e.target.value,
                      })
                    }}
                    value={this.state.name}
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="image">Gambar </Label>
                  <File
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      this.setState({
                        image: e.target.files,
                      })
                    }}
                    className="mb-2"
                  />

                  <span className="mt-2">
                    dimensi gambar 9:16, ukuran gambar 5MB, format gambar (.jpg
                    & .png)
                  </span>
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    outline
                    onClick={this.modalToggle.bind(this)}
                    type="button"
                  >
                    Batal
                  </Button>
                  <Button color="primary" type="submit" form="ministry-form">
                    Simpan
                  </Button>
                </div>
              </Form>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ministry: state.ministry,
  }
}

const mapDispatchToProps = {
  getMinistries: ministry.getMinistries,
  deleteMinistry: ministry.deleteMinistry,
  addMinistry: ministry.addMinistry,
  updateMinistry: ministry.updateMinistry,
}

export default connect(mapStateToProps, mapDispatchToProps)(Ministry)
