import { axiosInstance } from "../../../helper"
import { leader } from "../types"

export function getLeader(params, config) {
  return {
    type: leader.GET_LEADER,
    payload: new axiosInstance.get("/admin/leader", {
      ...config,
      params,
    }),
  }
}

export default getLeader
