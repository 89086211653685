import { Collapse, NavItem, NavLink } from "reactstrap"
import { useState } from "react"
import { useLocation } from "react-router-dom"

function CollapseRoute({ icon, namekey, name, to, children }) {
  const [collapseOpen, setCollapseOpen] = useState(false)
  const toggle = () => {
    setCollapseOpen(!collapseOpen)
  }
  const location = useLocation()

  return (
    <NavItem key={namekey}>
      <NavLink
        to={to}
        // tag={NavLinkRRD}
        activeClassName="active"
        className={`d-flex flex-row glow-collapse-link ${
          collapseOpen ? "glow-collapse-active" : ""
        } ${location.pathname.includes(to) ? "active" : ""}`}
        onClick={toggle}
      >
        <div className="glow-side-icon">{icon}</div>
        <div className="glow-side-name">{name}</div>
      </NavLink>

      <Collapse className="glow-collapse" isOpen={collapseOpen}>
        {children}
      </Collapse>
    </NavItem>
  )
}

export default CollapseRoute
