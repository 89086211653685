import { useState } from "react"

const File = ({
  onChange = (e) => {
    return e
  },
  accept,
  className,
  disabled,
  readOnly,
  id,
}) => {
  const [fileName, setFileName] = useState(null)

  return (
    <div className="custom-file mb-2">
      <input
        type="file"
        className={`custom-file-input ${className}`}
        id={id || `inputGroupFile`}
        aria-describedby="inputGroupFileAddon01"
        accept={accept}
        onChange={(e) => {
          if (e.target.files.length) {
            setFileName(e.target.files[0].name)
          }

          return onChange(e)
        }}
        disabled={disabled}
        readOnly={readOnly}
      />
      <label class="custom-file-label" htmlFor={id || `inputGroupFile`}>
        <span className="file-name">{fileName}</span>
      </label>
    </div>
  )
}

export default File
