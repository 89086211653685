import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  BreadcrumbItem,
  Breadcrumb,
  Input,
  Button,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
} from "reactstrap"
import ReactSelect from "react-select"

// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { connect } from "react-redux"
import { video } from "../../../redux/actions"
import { Icon } from "@iconify/react"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

const typeOptions = [
  {
    value: null,
    label: "None",
  },
  {
    value: 1,
    label: "Streaming Resource",
  },
  {
    value: 2,
    label: "Special Guest",
  },
]

class StreamingVideo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      params: {
        limit: 10,
        page: 1,
      },
      openDropDown: false,
      toggleDropdownList: [],
      selectValue: null,

      saveDisable: true,
    }
  }
  getVideos() {
    this.props.getVideos(this.state.params)
  }

  componentDidMount() {
    this.getVideos()
  }

  handlePageClick(e) {
    const oldState = { ...this.state }

    oldState.params.page = e.selected + 1

    this.setState(oldState, () => {
      this.getVideos()
    })
  }

  handleDelete(id, type) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteVideo(id)
      }
    })
  }

  deleteVideo(id) {
    this.props.deleteVideo(id).then(() => {
      toast.success("Berhasil Hapus Video")

      this.getVideos()
    })
  }

  typeToggle(id) {
    const oldState = this.state.toggleDropdownList
    oldState[id] = !oldState[id]

    this.setState({
      toggleDropdownList: oldState,
      selectValue: null,
      saveDisable: true,
    })
  }
  dropdownToggle() {
    this.setState({
      openDropDown: !this.state.openDropDown,
    })
  }

  getTypeVideo(type) {
    console.log("asda")
    if (type === 1) {
      return "Streaming Resource"
    }

    if (type === 2) {
      return "Special Guest"
    }

    return "None"
  }

  changeTypeVideo(id) {
    if (!this.state.selectValue) {
      return toast.error("Terjadi kesalahan, Mohon Pilih Tipe Video")
    }

    this.props
      .changeTypeVideo(id, {
        type: this.state.selectValue.value,
      })
      .then(() => {
        this.getVideos()

        toast.success("Berhasil Ubah Video")

        this.typeToggle(id)
      })
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem active>Video Resources</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="d-flex">
                  <div className="col-xl-4">
                    <Input
                      type="text"
                      className="form-control form-search"
                      placeholder="Judul Video"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.getVideos()
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          params: Object.assign({}, this.state.params, {
                            search: e.target.value,
                          }),
                        })
                      }}
                    />
                    {/* <span className='fa fa-search form-control-feedback'></span> */}
                  </div>

                  {/* <Dropdown
										isOpen={this.state.openDropDown}
										toggle={this.dropdownToggle.bind(this)}
									>
										<DropdownToggle
											tag='span'
											data-toggle='dropDown'
											aria-expanded={this.state.openDropDown}
										>
											<Button color='primary'>
												<Icon icon='ant-design:filter-filled' />
											</Button>
										</DropdownToggle>
										<DropdownMenu className='p-3' style={{ top: "20" }}>
											<ul>
												<li>abc</li>
											</ul>
										</DropdownMenu>
									</Dropdown> */}

                  <Link
                    to={{
                      pathname: "/admin/video-resource/create",
                    }}
                    className="btn btn-primary ml-auto"
                  >
                    + Tambah Video
                  </Link>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table-striped"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Judul Video</th>
                      <th scope="col">Creator</th>
                      {/* <th scope='col'>Video URL</th> */}
                      <th scope="col">Type</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.video.data.map((video, i) => {
                      return (
                        <tr>
                          <td>
                            {(this.props.video.page.currentPage - 1) *
                              this.state.params.limit +
                              i +
                              1}
                          </td>
                          <td className="text-align-start max-width-200">
                            {video.title}
                          </td>
                          <td>{video.Leader ? video.Leader.name : "Other"}</td>
                          {/* <td>
													<Link
														to={{
															pathname: video.youtube_url,
														}}
														className='mr-3 .text-decoration-underline'
														target='_blank'
													>
														{video.youtube_url}
													</Link>
												</td> */}
                          <td>
                            <Dropdown
                              isOpen={this.state.toggleDropdownList[video.id]}
                              toggle={() => {
                                this.typeToggle(video.id)
                              }}
                              size="lg"
                            >
                              <DropdownToggle
                                tag="span"
                                data-toggle="dropDown"
                                aria-expanded={
                                  this.state.toggleDropdownList[video.id]
                                }
                                className="cursor-pointer"
                              >
                                {video.OtherTypeVideo &&
                                video.OtherTypeVideo.is_active
                                  ? this.getTypeVideo(video.OtherTypeVideo.type)
                                  : "None"}
                                <Icon
                                  icon="mdi:pencil"
                                  color="var(--glow-primary)"
                                  className="ml-3"
                                />
                              </DropdownToggle>
                              <DropdownMenu
                                className="p-3"
                                style={{ top: "20" }}
                              >
                                <div className="d-flex flex">
                                  <ReactSelect
                                    className="mr-3 drop-select"
                                    placeholder="Pilih salah satu"
                                    options={typeOptions}
                                    onChange={(e) => {
                                      this.setState({
                                        selectValue: e,
                                        saveDisable: false,
                                      })
                                    }}
                                    defaultValue={typeOptions.find((value) => {
                                      const type = video.OtherTypeVideo
                                      const videoValue =
                                        type === null ? null : type.type
                                      return value.value === videoValue
                                    })}
                                  />
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      this.changeTypeVideo(video.id)
                                    }
                                    disabled={this.state.saveDisable}
                                  >
                                    Simpan
                                  </Button>
                                </div>
                              </DropdownMenu>
                            </Dropdown>
                            <div className="change-type"></div>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: `/admin/video-resource/${video.id}`,
                              }}
                              className="mr-3"
                            >
                              Lihat Detail
                            </Link>

                            <button
                              className="btn-link btn-link-danger"
                              onClick={() => {
                                this.handleDelete(video.id)
                              }}
                            >
                              Hapus
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4 d-flex justify-content-end align-items-center">
                  <p>
                    Menampilkan {this.props.video.data.length} dari{" "}
                    {this.props.video.page.totalData} data
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="&raquo;"
                    onPageChange={this.handlePageClick.bind(this)}
                    pageRangeDisplayed={1}
                    pageCount={this.props.video.page.totalPage}
                    marginPagesDisplayed={1}
                    previousLabel="&laquo;"
                    renderOnZeroPageCount={null}
                    className="pagination ml-3"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakLinkClassName="page-link"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    video: state.video,
  }
}

const mapDispatchToProps = {
  getVideos: video.getVideos,
  deleteVideo: video.deleteVideo,
  changeTypeVideo: video.changeTypeVideo,
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamingVideo)
