import { axiosInstance } from "../../../helper"
import { sermon } from "../types"

export function deleteItem(id, config) {
  return {
    type: sermon.DELETE,
    payload: new axiosInstance.delete(`/admin/sermon/${id}`, config),
  }
}

export default deleteItem
