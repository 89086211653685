import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { sermon } from "../../../redux/actions"

import { toast } from "react-toastify"
import { File } from "../../../components/Form"
import CreatableSelect from "react-select/creatable"
import Swal from "sweetalert2"

class CreateSermon extends Component {
  constructor(props) {
    super(props)

    this.state = {
      image: null,
      type: null,
      readOnly: this.props.match.params.sermonId !== "create",
      title: null,
      categoryValue: null,
      thumbnail: null,
      file: null,
    }
  }

  get data() {
    const formData = new FormData()

    formData.append("image", this.state.image)

    return formData
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  goBack() {
    if (this.props.match.params.sermonId === "create") {
      this.props.history.push("/admin/sermon")
    }

    return this.setState({
      readOnly: !this.state.readOnly,
    })
  }

  addSermon() {
    const form = new FormData()

    form.append("title", this.state.title)

    if (this.state.thumbnail) {
      form.append("thumbnail", this.state.thumbnail)
    }

    if (this.state.file) {
      form.append("file", this.state.file)
    }

    if (!this.state.categoryValue) {
      toast.error("Mohon Masukan Kategori")

      return
    }

    form.append("category", this.state.categoryValue.label)

    this.props.addSermon(form).then(() => {
      toast.success("Berhasil Tambah Khotbah")
      this.props.history.push("/admin/sermon")
    })
  }

  editSermon() {
    const form = new FormData()

    form.append("title", this.state.title)

    if (this.state.thumbnail) {
      form.append("thumbnail", this.state.thumbnail)
    }

    if (this.state.file) {
      form.append("file", this.state.file)
    }

    if (!this.state.categoryValue) {
      toast.error("Mohon Masukan Kategori")

      return
    }

    form.append("category", this.state.categoryValue.label)

    this.props.editSermon(this.props.match.params.sermonId, form).then(() => {
      toast.success("Berhasil Update Khotbah")
      this.props.history.push("/admin/sermon")
    })
  }

  getSermonDetail() {
    this.props.getSermonDetail(this.props.match.params.sermonId).then(() => {
      const detail = this.props.sermon.detail
      this.setState({
        title: detail.title,
        categoryValue: {
          label: detail.SermonCategory.name,
          value: detail.SermonCategory.id,
        },
        category: detail.SermonCategory.name,
        isModalShow: false,
        showImage: detail.thumbnail_url,
      })
    })
  }

  handleDelete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteSermon(this.props.match.params.sermonId).then(() => {
          Swal.fire("Deleted!", "Sermon has been deleted.", "success")
          this.props.history.push(`/admin/sermon`)
        })
      }
    })
  }

  componentDidMount() {
    this.getCategories()
    if (this.state.readOnly) {
      this.getSermonDetail()
    }
  }

  getCategories() {
    this.props.getCategories()
  }

  proxySubmit(e) {
    e.preventDefault()

    if (this.props.match.params.sermonId === "create") {
      return this.addSermon()
    }

    return this.editSermon()
  }

  get categories() {
    return this.props.sermon.categories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      }
    })
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/sermon">Khotbah</Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Tambah Khotbah</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <Row>
                <Col xl={8}>
                  <Form onSubmit={this.proxySubmit.bind(this)} id="sermon-form">
                    <FormGroup>
                      <Label htmlFor="title">Judul Khotbah</Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        placeholder="Masukan Judul"
                        disabled={this.state.readOnly}
                        value={this.state.title}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="file">File Khotbah</Label>
                      <File
                        accept="application/pdf"
                        onChange={(e) => {
                          this.setState({ file: e.target.files[0] })
                        }}
                        className="mb-2"
                        disabled={this.state.readOnly}
                        id="inputGroupFile01"
                      />

                      <span>
                        ukuran file max 5MB, format file (.pdf)
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="image">Gambar Khotbah</Label>

                      <Row>
                        <Col xl={10}>
                          <File
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              this.setState({ thumbnail: e.target.files[0] })
                            }}
                            disabled={this.state.readOnly}
                            id="inputGroupFile02"
                          />
                        </Col>

                        <Col xl={2} className="d-flex align-items-center">
                          <button
                            // color="link"
                            className="btn btn-link"
                            type="button"
                            onClick={this.modalToggle.bind(this)}
                            disabled={!this.state.showImage}
                          >
                            Tampilkan
                          </button>
                        </Col>
                      </Row>
                      <span>
                        dimensi gambar 9:16, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Kategori Khotbah</Label>
                      <CreatableSelect
                        isClearable
                        isDisabled={this.state.readOnly}
                        onChange={(e) => {
                          if (e["__isNew__"]) {
                            this.setState({
                              categoryValue: e,
                              sermon_category_id: null,
                              category: e.label,
                            })
                          } else {
                            this.setState({
                              categoryValue: e,
                              sermon_category_id: e.value,
                              category: e.label,
                            })
                          }
                        }}
                        options={this.categories}
                        placeholder="Pilih atau ketik Kategori"
                        value={this.state.categoryValue}
                      />
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                {this.state.readOnly ? (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={this.handleDelete.bind(this)}
                    >
                      Hapus
                    </Button>
                    <div>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          this.setState({
                            readOnly: !this.state.readOnly,
                          })
                        }}
                      >
                        Edit Khotbah
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={() => {
                        this.props.history.push("/admin/sermon")
                      }}
                    >
                      Batal
                    </Button>
                    <Button color="primary" type="submit" form="sermon-form">
                      Simpan
                    </Button>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}

const mapDispatchToProps = {
  getCategories: sermon.getCategories,
  getSermonDetail: sermon.getSermonDetail,
  deleteSermon: sermon.deleteItem,
  addSermon: sermon.addItem,
  editSermon: sermon.updateItem,
}

const mapStateToProps = (state) => {
  return { sermon: state.sermon }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSermon)
