import { axiosInstance } from "../../../helper"
import { imageSystem } from "../types"

export function getSplashScreen(params, config) {
  return {
    type: imageSystem.GET_SPLASH,
    payload: new axiosInstance.get(`/admin/popup-image/splash`, {
      ...config,
      params,
    }),
  }
}

export default getSplashScreen
