import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap"

import Select from "react-select"

// core components
import { Component } from "react"
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { banner, event } from "../../../redux/actions"

import { toast } from "react-toastify"
import { File } from "../../../components/Form"

class CreateEventBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      image: null,
      index: null,
      eventValue: null,

      isModalShow: false,
      showImage: null,

      params: {},
    }
  }

  get data() {
    const formData = new FormData()

    formData.append("image", this.state.image)
    formData.append("index", this.state.index)
    if (this.state.eventValue && this.state.eventValue.value !== null) {
      formData.append(
        "event_id",
        this.state.eventValue ? this.state.eventValue.value : null
      )
    }

    return formData
  }

  getEvents() {
    this.props.getEvents({ type: 2 })
  }

  getTotalIndex() {
    this.props.totalEventBanner()
  }

  componentDidMount() {
    this.getTotalIndex()
    this.getEvents()
  }

  eventSubmit() {
    this.props
      .addEventBanner(this.data)
      .then(() => {
        toast.success("Tambah Banner Event Berhasil")
        this.props.history.push("/admin/new-banner/event")
      })
      .catch((err) => {
        toast.error(err.response ? err.response.data.message : err.message)
      })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (!this.state.image) {
      return toast.error("Gambar Belum diisi")
    }

    return this.eventSubmit()
  }

  get events() {
    const events = this.props.event.data.map((event) => {
      return {
        label: event.name,
        value: event.id,
      }
    })

    events.push({
      label: "Lainnya",
      value: null,
    })

    return events
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/new-banner/event">Banner</Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Tambah Banner Event</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <Row>
                <Col xl={8}>
                  <Form
                    onSubmit={this.proxySubmit.bind(this)}
                    id="create-banner"
                  >
                    <FormGroup>
                      <Label htmlFor="exampleSelect">Event</Label>
                      <Select
                        isClearable
                        isDisabled={this.state.readOnly}
                        onChange={(e) => {
                          this.setState({
                            eventValue: e,
                          })
                        }}
                        options={this.events}
                        placeholder="Pilih Event"
                        value={this.state.eventValue}
                        isSearchable
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="image">Gambar Banner</Label>

                      <Row>
                        <Col xl={10}>
                          <File
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              this.setState({
                                image: e.target.files[0],
                                showImage: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              })
                            }}
                          />
                        </Col>

                        <Col xl={2} className="d-flex align-items-center">
                          <button
                            // color="link"
                            className="btn btn-link"
                            type="button"
                            onClick={this.modalToggle.bind(this)}
                            disabled={!this.state.showImage}
                          >
                            Tampilkan
                          </button>
                        </Col>
                      </Row>
                      <span>
                        dimensi gambar 16:9, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Urutan Banner</Label>
                      <Row>
                        <Col xl={6}>
                          <Input
                            type="select"
                            onChange={(e) =>
                              this.setState({ index: e.target.value })
                            }
                          >
                            <option value="" selected disabled>
                              Pilih Index
                            </option>
                            {this.props.indexList.map((a) => {
                              return <option value={a}>{a}</option>
                            })}
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    this.props.history.push("/admin/new-banner/event")
                  }}
                >
                  Batal
                </Button>
                <Button color="primary" type="submit" form="create-banner">
                  Simpan
                </Button>
              </div>
            </CardBody>
          </Card>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader
              toggle={this.modalToggle.bind(this)}
              cssModule={{
                "modal-title": "h3",
              }}
            >
              Preview Image
            </ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.event,
    indexList: state.banner.eventIndex,
  }
}

const mapDispatchToProps = {
  addEventBanner: banner.addEventBanner,
  addInfoBanner: banner.addInfoBanner,
  getEvents: event.getEventsNe,
  totalEventBanner: banner.totalEventBanner,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventBanner)
