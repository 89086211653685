import { Component } from "react"
import { connect } from "react-redux"
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import { Icon } from "@iconify/react"

import { magazine } from "../../../redux/actions"
import { toast } from "react-toastify"

class WeeklyMagz extends Component {
  getMagazine() {
    this.props.getMagazine()
  }

  deleteMagazine(id) {
    this.props.deleteMagazine(id).then(() => {
      toast.success("Berhasil Hapus Magazine")
      this.getMagazine()
    })
  }

  updateMagazine(id, data) {
    this.props.updateMagazine(id, data).then(() => {
      toast.success("Update Magazine Berhasil")
      this.getMagazine()

      this.forceUpdate()
    })
  }

  handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        return this.deleteMagazine(id)
      }
    })
  }

  componentDidMount() {
    this.getMagazine()
  }

  render() {
    return (
      <Container fluid className="mb-5">
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Majalah Mingguan</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <p>*Maksimal upload 10 Majalah</p>

        <div className="d-flex justify-content-end">
          {/* Page content */}
          <Link
            to="/admin/content-management/weekly-magz/create"
            className="btn btn-primary"
          >
            Tambah Majalah
          </Link>
        </div>

        <Row className="mb-4 mt-3">
          {this.props.magazine.data.map((magazine) => (
            <Col xl={3}>
              <Card className="mb-3 banner">
                <div className="card-img-top">
                  <img src={magazine.thumbnail_url} alt={"test"} width="100%" />
                  <div
                    className="del-card"
                    onClick={() => {
                      this.handleDelete(magazine.id)
                    }}
                  >
                    <Icon icon="akar-icons:circle-x-fill" fontSize={"35px"} />
                  </div>
                </div>
                <CardBody>
                  <h5>{magazine.title}</h5>
                  <div className="row">
                    <div className="col-4">
                      <select
                        className="form-control form-control-sm"
                        defaultValue={magazine.priority}
                        value={magazine.priority}
                        onChange={(e) => {
                          const formData = new FormData()
                          formData.append("title", magazine.title)
                          formData.append("index", e.target.value)
                          this.updateMagazine(magazine.id, formData)
                        }}
                      >
                        {Array(this.props.magazine.data.length)
                          .fill(1)
                          .map((el, i) => (
                            <option value={i + 1}>{i + 1}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-8">
                      <Link
                        className="btn btn-primary btn-sm btn-block"
                        to={`/admin/content-management/weekly-magz/${magazine.id}`}
                      >
                        Edit Majalah
                      </Link>
                      {/* <Button color="primary" size="sm" block></Button> */}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    magazine: state.magazine,
  }
}

const mapDispatchToProps = {
  getMagazine: magazine.getMagazine,
  deleteMagazine: magazine.deleteMagazine,
  updateMagazine: magazine.updateMagazine,
}

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyMagz)
