import { magazine } from "../types"
import { axiosInstance } from "../../../helper"

export function updateMagazine(id, body, config) {
  return {
    type: magazine.UPDATE_MAGAZINE,
    payload: new axiosInstance.put(
      `/admin/weekly-magazine/${id}`,
      body,
      config
    ),
  }
}

export default updateMagazine
