import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { banner } from "../../../redux/actions"

import { toast } from "react-toastify"
import { File } from "../../../components/Form"

class CreateInformationBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      image: null,
      type: null,

      title: null,
      description: null,
      index: null,

      isModalShow: false,
      showImage: null,
    }
  }

  get data() {
    const formData = new FormData()

    formData.append("image", this.state.image)
    formData.append("title", this.state.title)
    formData.append("description", this.state.description)
    formData.append("index", this.state.index)

    return formData
  }

  infoSubmit() {
    this.props.addInfoBanner(this.data).then(() => {
      toast.success("Tambah Banner Informasi Berhasil")
      this.props.history.push("/admin/new-banner/information")
    })
  }

  proxySubmit(e) {
    e.preventDefault()

    if (!this.state.image) {
      return toast.error("Gambar Belum diisi")
    }

    return this.infoSubmit()
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  getTotalIndex() {
    this.props.totalInfoBanner()
  }

  componentDidMount() {
    this.getTotalIndex()
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/new-banner/information">Banner</Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Tambah Banner Informasi</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <Row>
                <Col xl={8}>
                  <Form
                    onSubmit={this.proxySubmit.bind(this)}
                    id="create-banner"
                  >
                    <FormGroup>
                      <Label htmlFor="name">Judul Informasi</Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        placeholder="Masukkan Judul Informasi"
                        value={this.state.title}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="image">Gambar Banner</Label>

                      <Row>
                        <Col xl={10}>
                          <File
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              this.setState({
                                image: e.target.files[0],
                                showImage: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              })
                            }}
                          />
                        </Col>

                        <Col xl={2} className="d-flex align-items-center">
                          <button
                            // color="link"
                            className="btn btn-link"
                            type="button"
                            onClick={this.modalToggle.bind(this)}
                            disabled={!this.state.showImage}
                          >
                            Tampilkan
                          </button>
                        </Col>
                      </Row>
                      <span>
                        dimensi gambar 16:9, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Deskripsi</Label>
                      <Input
                        type="textarea"
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        placeholder="Masukkan Informasi Banner"
                        value={this.state.description}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="name">Urutan Banner</Label>
                      <Row>
                        <Col xl={6}>
                          <Input
                            type="select"
                            onChange={(e) =>
                              this.setState({ index: e.target.value })
                            }
                          >
                            <option value="" selected disabled>
                              Pilih Index
                            </option>

                            {this.props.indexList.map((a) => {
                              return <option value={a}>{a}</option>
                            })}
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                {this.state.disable ? (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={this.handleDelete.bind(this)}
                    >
                      Hapus
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        this.setState({
                          disable: !this.state.disable,
                        })
                      }}
                    >
                      Edit Schedule
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={() => {
                        this.props.history.push("/admin/new-banner/information")
                      }}
                    >
                      Batal
                    </Button>
                    <Button color="primary" type="submit" form="create-banner">
                      Simpan
                    </Button>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader
              toggle={this.modalToggle.bind(this)}
              cssModule={{
                "modal-title": "h3",
              }}
            >
              Preview Image
            </ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}

const mapDispatchToProps = {
  addEventBanner: banner.addEventBanner,
  addInfoBanner: banner.addInfoBanner,
  totalInfoBanner: banner.totalInfoBanner,
}

const mapStateToProps = (state) => {
  return {
    indexList: state.banner.infoIndex,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInformationBanner)
