import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { connect } from "react-redux"
import { ministry } from "../../../redux/actions"
import { File } from "../../../components/Form"

class UpdateMinistryContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: "",
      description: "",
      image: null,
      isModalShow: false,
      showImage: null,
    }
  }

  modalToggle() {
    this.setState({
      isModalShow: !this.state.isModalShow,
    })
  }

  goBack() {
    this.props.history.push("/admin/ministry")
  }

  getMinistryContent() {
    this.props
      .getMinistryContent(this.props.match.params.categoryId)
      .then(() => {
        const detail = this.props.ministry.content
        this.setState({
          title: detail.title,
          description: detail.description,
          isModalShow: false,
          showImage: detail.image,
        })
      })
  }

  componentDidMount() {
    this.getMinistryContent()
  }

  proxySubmit(e) {
    e.preventDefault()
    return this.updateMinistryContent()
  }

  updateMinistryContent() {
    const formData = new FormData()

    formData.append("title", this.state.title)
    formData.append("price", this.state.price)
    formData.append("wa_phone", this.state.wa_phone)
    formData.append("description", this.state.description)
    if (this.state.image) {
      formData.append("image", this.state.image[0])
    }

    this.props
      .updateMinistryContent(this.props.match.params.categoryId, formData)
      .then(() => {
        toast.success("Berhasil Update Konten")

        this.goBack()
      })
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin/ministry">Ministry</Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Update Konten</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Card>
            <CardBody>
              <h1 className="mb-4">Detail Konten</h1>
              <Row>
                <Col xl={8}>
                  <Form
                    onSubmit={this.proxySubmit.bind(this)}
                    id="update-content"
                  >
                    <FormGroup>
                      <Label htmlFor="title">Judul Konten</Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Masukan Judul Konten"
                        onChange={(e) => {
                          this.setState({
                            title: e.target.value,
                          })
                        }}
                        value={this.state.title}
                      />
                    </FormGroup>

                    <Row>
                      <Col xl={10}>
                        <FormGroup>
                          <Label htmlFor="description">Isi Konten</Label>
                          <Input
                            type="textarea"
                            name="description"
                            id="description"
                            placeholder="Masukan Isi Konten"
                            onChange={(e) => {
                              this.setState({
                                description: e.target.value,
                              })
                            }}
                            value={this.state.description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label htmlFor="image">Gambar</Label>
                      <Row>
                        <Col xl={10}>
                          <File
                            onChange={(e) => {
                              this.setState({
                                image: e.target.files,
                                showImage: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              })
                            }}
                            accept="image/png, image/gif, image/jpeg"
                            className="mb-2"
                          />
                        </Col>

                        <Col xl={2}>
                          <Button
                            color="primary"
                            type="button"
                            onClick={this.modalToggle.bind(this)}
                            disabled={!this.state.showImage}
                          >
                            Tampilkan
                          </Button>
                        </Col>
                      </Row>

                      <span className="mt-2">
                        dimensi gambar 16:9, ukuran gambar 5MB, format gambar
                        (.jpg & .png)
                      </span>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Container className="d-flex">
                <div className="d-flex flex-row align-items-center mb-3 ml-auto">
                  <Button
                    color="primary"
                    outline
                    onClick={this.goBack.bind(this)}
                  >
                    Batal
                  </Button>
                  <Button color="primary" type="submit" form="update-content">
                    Simpan
                  </Button>
                </div>
              </Container>
            </CardBody>
          </Card>
          <Modal
            toggle={this.modalToggle.bind(this)}
            isOpen={this.state.isModalShow}
          >
            <ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
            <ModalBody>
              <Container>
                <img src={this.state.showImage} width="100%" alt="" />
              </Container>
            </ModalBody>
          </Modal>
        </Container>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    ministry: state.ministry,
  }
}

const mapDispatchToProps = {
  getMinistryContent: ministry.getMinistryContent,
  updateMinistryContent: ministry.updateMinistryContent,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateMinistryContent)
